import { loggerWithPrefix } from "@/lib/logger";
import eventTracker from "@/lib/trackers/eventTracker";
import { Static, Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { PostHog as PostHogJS } from "posthog-js";
import { PostHog as PostHogNode } from "posthog-node";

const logger = loggerWithPrefix("[PostHog]");

const PostHogMode = Type.Union([
  Type.Literal("enabled"),
  Type.Literal("disabled"),
  Type.Literal("no-events"),
  Type.Literal("debug"),
]);
type PostHogMode = Static<typeof PostHogMode>;
function isPostHogMode(u: unknown): u is PostHogMode {
  return Value.Check(PostHogMode, u);
}

function getPostHogMode(): PostHogMode {
  const NEXT_PUBLIC_POSTHOG_MODE = process.env.NEXT_PUBLIC_POSTHOG_MODE;
  if (!NEXT_PUBLIC_POSTHOG_MODE) {
    if (process.env.CI) return "disabled";
    // you can enable posthog in dev by setting NEXT_PUBLIC_POSTHOG_MODE=debug
    if (process.env.NODE_ENV === "development") return "no-events";
    return process.env.NODE_ENV === "production" ? "enabled" : "disabled";
  }
  if (!isPostHogMode(NEXT_PUBLIC_POSTHOG_MODE)) {
    logger.error(`Invalid NEXT_PUBLIC_POSTHOG_MODE: ${NEXT_PUBLIC_POSTHOG_MODE}`);
    return "disabled";
  }
  return NEXT_PUBLIC_POSTHOG_MODE;
}

export function getPostHogApiKey(): string | undefined {
  if (getPostHogMode() === "disabled") {
    return undefined;
  }
  const NEXT_PUBLIC_POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  if (!NEXT_PUBLIC_POSTHOG_KEY) {
    logger.error("NEXT_PUBLIC_POSTHOG_KEY is not set, PostHog is disabled");
  }
  return NEXT_PUBLIC_POSTHOG_KEY;
}

export const posthogClientConfig = {
  api_host: "/posthog/",
  ui_host: "https://us.posthog.com",
  opt_in_site_apps: true,
  person_profiles: "identified_only",
  disable_session_recording: false,
  feature_flag_request_timeout_ms: 1000,
  loaded: (posthog: PostHogJS) => {
    const GIT_HASH = process.env.GIT_HASH;
    if (GIT_HASH) {
      posthog.register({
        deployment_id: GIT_HASH,
      });
    }
    const mode = getPostHogMode();
    if (mode === "debug") {
      posthog.debug(true);
      posthog.opt_in_capturing();
    } else if (mode === "no-events") {
      posthog.opt_out_capturing();
    }
  },
} as const;

function createPostHogStub(): PostHogNode {
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (typeof prop === "string") {
          return (...args: unknown[]) => {
            logger.debug(`PostHog.${prop}`, { ...args });
          };
        }
      },
    },
  ) as PostHogNode;
}

function createServerInstance(): PostHogNode {
  const apiKey = getPostHogApiKey();
  if (!apiKey) {
    return createPostHogStub();
  }
  const result = new PostHogNode(apiKey, {
    featureFlagsPollingInterval: 300000, // 5 minutes
    host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://us.i.posthog.com",
    personalApiKey: process.env.POSTHOG_PERSONAL_API_KEY,
  });
  result.debug(getPostHogMode() === "debug");
  return result;
}

let serverInstance: PostHogNode | undefined;
if (typeof window === "undefined") {
  serverInstance = createServerInstance();
  eventTracker.posthogNode = serverInstance;
}
export { serverInstance };

import { loggerWithPrefix } from "@/lib/logger";
import { atom } from "nanostores";

const logger = loggerWithPrefix("[onboardingV2Store]");

export enum OnboardingLocalStorage {
  ExploreProfilesClosed = "onboardingV2_exploreProfilesClosed",
  InstallChromeExtensionClosed = "onboardingV2_installChromeExtensionClosed",
}

class OnboardingV2Store {
  // Sections disappear based on actions taken by the user, or if they are
  // explicitly dismissed. In the current iteration whether or not they have
  // been dismissed by the user is stored in localStorage, so the sections
  // will reappear if the user changes the browser. Eventually we might move
  // it to the database, but for now we are okay with nagging the user to
  // do the right thing.
  exploreProfilesSectionClosed = atom<boolean>(false);
  installChromeExtensionSectionClosed = atom<boolean>(false);

  init(recentVisitCount: number) {
    if (localStorage.getItem(OnboardingLocalStorage.ExploreProfilesClosed) === "true") {
      this.exploreProfilesSectionClosed.set(true);
    }
    if (localStorage.getItem(OnboardingLocalStorage.InstallChromeExtensionClosed) === "true") {
      this.installChromeExtensionSectionClosed.set(true);
    }
    if (recentVisitCount >= 4) {
      this.exploreProfilesSectionClosed.set(true);
    }
  }

  closeExploreProfilesSection() {
    this.exploreProfilesSectionClosed.set(true);
    localStorage.setItem(OnboardingLocalStorage.ExploreProfilesClosed, "true");
  }

  closeInstallChromeExtensionSection() {
    this.installChromeExtensionSectionClosed.set(true);
    localStorage.setItem(OnboardingLocalStorage.InstallChromeExtensionClosed, "true");
  }
}

export const onboardingV2Store = new OnboardingV2Store();

import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import { assertUnreachable } from "@/lib/miscUtils";
import { uiStore } from "@/stores/uiStore";
import { FeatureFlag, userMeta } from "@/types";
import { useStore } from "@nanostores/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback } from "react";
import { canLoadEntity } from "@/utils/visits";

// open the entity sidebar when clicking on an entity card
export function useEntitySidebarClick(
  entityId: string | undefined,
  entityUrl: string | undefined,
  onClickDefault?: (e: React.MouseEvent) => void,
) {
  const sidebar = useSidebar();
  const useNewOnboarding = useFeatureFlagEnabled(FeatureFlag.OnboardingV2) || false;
  const openInSidebar = true;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClickDefault) onClickDefault(e);
      if (e.defaultPrevented) return;

      if (openInSidebar && (entityId || entityUrl)) {
        sidebar.openSidebar(
          SidebarVariant.Entity,
          entityId ? { entityId }
          : entityUrl ? { entityUrl }
          : assertUnreachable(),
        );
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [entityId, entityUrl, onClickDefault, sidebar, openInSidebar, useNewOnboarding],
  );

  return onClick;
}

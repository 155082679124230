import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import EntityAIChat from "./EntityAIChat";
import EntityHighlights from "./EntityHighlights";

export default function EntityTabs() {
  const tabs = [
    { name: "Highlights", id: "highlights", component: <EntityHighlights /> },
    { name: "AI Chat", id: "ai-chat", component: <EntityAIChat /> },
  ];

  return (
    <TabGroup>
      <TabList className="flex border-b border-gray-200">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            className={({ selected }) =>
              clsx(
                "px-4 py-2 text-sm font-medium leading-5",
                "focus:outline-none focus:ring-0",
                selected ?
                  "text-brand-600 border-b-2 border-brand-600"
                : "text-gray-500 hover:text-gray-700",
              )
            }
          >
            {tab.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-4">
        {tabs.map((tab) => (
          <TabPanel key={tab.id}>{tab.component}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}

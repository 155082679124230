/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import FactsList from "@/components/people/FactsList";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import SocialButtons from "@/components/people/SocialButtons";
import { useSidebar } from "@/components/providers/SidebarProvider";
import EntityHighlights from "@/components/sections/EntityHighlights";
import EntityTabs from "@/components/sections/EntityTabs";
import ProfileBubble from "@/components/sections/ProfileBubble";
import SupportButton from "@/components/support/SupportButton";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useDevTools } from "@/hooks/useUIStore";
import { CompanyFact } from "@/models/facts/facts.types";
import { getMainEntityStore, useEntityStore } from "@/stores/entityStore";
import { MessageStoreType, useMessageStore } from "@/stores/messageStore";
import { uiStore } from "@/stores/uiStore";
import { AllTags, TagId } from "@/tags/tags";
import {
  EntityType,
  EntityUIType,
  NO_DESCRIPTION,
  PipelineRunStatus,
  ProfilePageSection,
} from "@/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { useEffect, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

export default withErrorBoundary(function EntityHeaderV2({
  status,
}: {
  status: PipelineRunStatus;
}) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const messageStore = useMessageStore();
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const attributes = useStore(entityStore.attributes);
  const facts = useStore(entityStore.facts);
  const snapshot = useStore(entityStore.snapshot);
  const companyBrandedBlurb = facts[CompanyFact.BrandedBlurb]?.value;
  const isDev = useDevTools();

  const queueValue = updateQueue[entity.id];
  const isRegenerating = queueValue && !queueValue.resolved;

  const entityProps = { entity, attributes };

  const entityDescription =
    entity.type === EntityType.Company ? companyBrandedBlurb
    : entity.description?.startsWith(NO_DESCRIPTION) ? undefined
    : entity.description;

  const isSidebar = entityStore.type === EntityUIType.Sidebar;
  const sidebar = useSidebar();

  // Initialize the message store for entity chat
  useEffect(() => {
    if (entityStore.type === EntityUIType.Main) {
      if (!entity.id) return;

      messageStore.init(MessageStoreType.Entity, entity.id);

      return () => {
        messageStore.interrupt();
      };
    }
  }, [messageStore, entity.id, entityStore.type]);

  const clickOpenProfile = (e?: React.MouseEvent) => {
    if (!isSidebar) return;

    // Open in new tab if cmd/ctrl key or middle mouse button
    if (e?.metaKey || e?.ctrlKey || e?.button === 1) {
      window.open(`${entity.slug}`, "_blank");
      return;
    }

    getMainEntityStore().loadFromSidebar();
    sidebar.closeSidebar();
  };

  return (
    <ProfileBubble className="flex flex-col gap-3" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1 max-w-full">
          <ProfileEntityImage entity={entity} />
          <div className="flex-1 flex flex-col gap-1 max-w-full overflow-hidden">
            <div className="flex">
              <h1
                className={twJoin(
                  "text-lg md:text-2xl font-semibold flex-1 mr-2",
                  isSidebar && "cursor-pointer hover:underline",
                )}
                onClick={(e) => (isSidebar ? clickOpenProfile(e) : undefined)}
              >
                {entity.name}
              </h1>
              <div className="flex text-gray-400 flex-row flex-wrap sm:justify-end items-center gap-4 max-w-full">
                <div className="hidden sm:flex items-center gap-3">
                  {!isSidebar && (
                    <div className="hidden md:flex items-center gap-3">
                      <SocialButtons />
                      <SupportButton />
                    </div>
                  )}
                  {isSidebar ?
                    <button
                      className="text-white bg-brand-600 px-2 py-1 rounded hover:bg-brand-700 flex items-center h-min"
                      onClick={(e) => clickOpenProfile(e)}
                    >
                      <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                      Open Profile
                    </button>
                  : null}
                </div>
                <ProfilePopoverMenu
                  entity={entity}
                  isRegenerating={isRegenerating}
                  status={status}
                />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle skipLastUpdated {...entityProps} />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "flex flex-row gap-2 items-center justify-between md:hidden",
          isSidebar && "md:flex",
        )}
      >
        <SocialButtons />
      </div>

      <div className="text-sm">{entityDescription}</div>

      <FactsList />
      {snapshot?.tags && snapshot.tags.length > 0 && (
        <div className="flex flex-wrap gap-2 text-gray-600">
          {snapshot?.tags?.map((tag) => (
            <div
              key={tag}
              data-tooltip-id="tooltip"
              data-tooltip-content={AllTags[tag as TagId]?.description}
              className="bg-gray-100 rounded-md p-1"
            >
              {AllTags[tag as TagId]?.name || tag}
            </div>
          ))}
        </div>
      )}

      {isDev && entityStore.type === EntityUIType.Main ?
        <EntityTabs />
      : <EntityHighlights />}
    </ProfileBubble>
  );
});

function ProfileEntityImage(props: { entity: Entity }) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden flex-shrink-0", isAdmin && "cursor-pointer")}
        onClick={isAdmin ? () => setOpen(true) : undefined}
      >
        <EntityIconWithPlaceholder className="h-16 w-16" imageClassName="h-16 w-16" {...props} />
      </div>
    </>
  );
}

import { SocialService } from "@/utils/socialUtils";
import FacebookIcon from "./FacebookIcon";
import GithubIcon from "./GithubIcon";
import LinkedinIcon from "./LinkedinIcon";
import TwitterIcon from "./TwitterIcon";
import { IconProps } from "./types";

interface SocialServiceIconProps extends IconProps {
  service: SocialService;
}

export function SocialServiceIcon({ service, ...props }: SocialServiceIconProps) {
  switch (service) {
    case SocialService.Facebook:
      return <FacebookIcon {...props} />;
    case SocialService.Twitter:
      return <TwitterIcon {...props} />;
    case SocialService.GitHub:
      return <GithubIcon {...props} />;
    case SocialService.LinkedIn:
      return <LinkedinIcon {...props} />;
    default:
      return null;
  }
}

import moment from "moment";
import { ReactNode } from "react";

import EditableFact from "@/components/entities/EditableFact";
import DelimitedList from "@/components/ui/DelimitedList";
import { prettyUrl } from "@/lib/utils";
import { PersonFact } from "@/models/facts/facts.types";
import { PersonCompanyRelationship } from "@/models/relationship/relationshipTypes";
import { useEntityStore } from "@/stores/entityStore";
import { Attribute } from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity;
  attributes: Attribute[];
  skipLastUpdated?: boolean;
};

export default function PersonSubtitle(props: Props) {
  const entityStore = useEntityStore();
  const facts = useStore(entityStore.facts);
  const relationships = useStore(entityStore.relationships);
  const canEditAttrs = useStore(entityStore.canEdit);

  const { entity, skipLastUpdated } = props;

  const subtitles: (ReactNode | string)[] = [];

  const title = entity.title;

  if (title) {
    subtitles.push(title);
  }

  const currentCompany = relationships
    ?.filter((r) => r.type === PersonCompanyRelationship.WorkedAt)
    ?.find((r) => r.endedDate === null || r.endedDate === "Present" || r.endedDate === "")
    ?.to?.name;

  if (currentCompany) {
    subtitles.push(currentCompany);
  }

  const fact = facts[PersonFact.Location];

  const location = fact?.value;
  if (location) {
    const locationText = location.split(", ").slice(0, 2).join(", ");
    subtitles.push(
      <EditableFact
        factType={PersonFact.Location}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: PersonFact.Location,
            currentValue: location,
          })
        }
      >
        Location: {locationText}
      </EditableFact>,
    );
  }

  if (entity.generatedAt && !skipLastUpdated)
    subtitles.push("Last Updated: " + moment(entity.generatedAt).fromNow());

  if (entity.url && !entity.url.includes("linkedin.com/") && !entity.url.includes("distill://")) {
    subtitles.push(
      <a href={entity.url} className="text-blue-500 hover:text-blue-700">
        {prettyUrl(entity.url)}
      </a>,
    );
  }

  return (
    <div className="text-13pt md:text-sm inline-flex flex-wrap overflow-hidden max-w-full">
      <DelimitedList
        list={subtitles.filter(Boolean)}
        spacing="mx-1"
        itemClass="whitespace-nowrap overflow-hidden text-ellipsis truncate"
      />
    </div>
  );
}

import Head from "next/head";
import Image from "next/image";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import ConfirmModal from "@/components/modals/ConfirmModal";
import InputModal from "@/components/modals/InputModal";
import Navbar from "@/components/navbar/Navbar";
import CookieBanner from "@/components/ui/CookieBanner";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { classNames } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";
import VisitLimitReachedModal from "@/components/modals/VisitLimitReachedModal";
import { defaultPageWidthClass } from "@/styles/constants";

export default function PageLayout({
  children,
  title,
  titleButtons,
  initialQuery,
  className,
  outerClassName,
  fullWidth,
  noScroll,
  noSearch,
  noCookieBanner,
}: {
  title?: string;
  titleButtons?: ReactNode;
  initialQuery?: string;
  children: ReactNode;
  className?: string;
  outerClassName?: string;
  fullWidth?: boolean;
  noScroll?: boolean;
  noSearch?: boolean;
  noCookieBanner?: boolean;
}) {
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <div
        className={classNames("flex-1 max-h-full flex flex-col overflow-hidden", outerClassName)}
      >
        <Navbar initialQuery={initialQuery} noSearch={noSearch} titleButtons={titleButtons} />

        <div
          id="container"
          className={classNames(
            "flex-1 flex flex-col bg-gray-50",
            noScroll ? "overflow-hidden" : "overflow-auto",
          )}
        >
          <div
            className={twMerge(
              "p-1 sm:pt-4 flex-1 flex flex-col",
              !fullWidth && defaultPageWidthClass,
              noScroll && "overflow-hidden",
              className,
            )}
          >
            {!noCookieBanner && <CookieBanner />}
            {title && (
              <h1 className="m-2 sm:mx-0 text-2xl font-light tracking-tight text-slate-700">
                {title}
              </h1>
            )}
            <ErrorBoundary
              fallback={<div className="p-20 flex justify-center">Something went wrong</div>}
            >
              {children}
            </ErrorBoundary>
          </div>
          <ConfirmModal />
          <InputModal />
          <VisitLimitReachedModal />
        </div>
      </div>
    </>
  );
}

export function SidebarButton() {
  const sidebarVisible = useStore(uiStore.sidebarVisible);

  return (
    <div
      className={classNames(
        "rounded-md hover:bg-gray-100 mr-4 cursor-pointer",
        sidebarVisible && "sm:hidden",
      )}
      data-tooltip-content="Show Sidebar"
      data-tooltip-id="tooltip"
      onClick={() => {
        uiStore.toggleSidebar();
      }}
    >
      <Image src="/icon-256.png" width={32} height={32} alt="Logo" />
    </div>
  );
}

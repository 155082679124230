import { Attribute } from "@/types";
import { Entity } from "@prisma/client";

export type EntityProps = {
  entity: Entity;
  attributes: Attribute[];
};

export type Tab = {
  id: string;
  name: string;
  onClick?: () => void;
  hidden?: boolean;
  count?: number;
  tooltip?: string;
};

// Used in EntityCard
export type PersonCardData = {
  entity: Entity;
  currentPosition?: string;
  currentCompany?: string;
  location?: string;
  directlyConnected?: boolean;
  mutuals?: number;
  birthYear?: number;
  pronouns?: string;
};

export type CompanyCardData = {
  entity: Entity;
  mutuals?: number;
  employees?: number;
  location?: string;
  founded?: number;
  website?: string;
  totalFundsRaised?: string;
  latestRoundSize?: string;
  industry?: string;
};
export const NO_DESCRIPTION = "No description available";

export enum EntityUIType {
  Sidebar = "sidebar",
  Main = "main",
}

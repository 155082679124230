import Rollbar, { Locals } from "rollbar";

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureUsername: true,
  captureEmail: true,
  enabled: process.env.NODE_ENV === "production", // Disable Rollbar in development
  environment: process.env.NODE_ENV || "development",
  logLevel: "error" as Rollbar.Level,
  reportLevel: "error" as Rollbar.Level,
};

export const clientConfig = {
  ...baseConfig,
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  client: {
    javascript: {
      source_map_enabled: true,
      code_version: process.env.GIT_HASH,
      // Optionally have Rollbar guess which frames the error was
      // thrown from when the browser does not provide line
      // and column numbers.
      guess_uncaught_frames: true,
    },
  },
} as const;

let serverInstance: Rollbar | undefined;
if (process.env.ROLLBAR_SERVER_TOKEN) {
  serverInstance = new Rollbar({
    ...baseConfig,
    accessToken: process.env.ROLLBAR_SERVER_TOKEN,
    itemsPerMinute: 50000,
    locals: Locals,
  });
}
export { serverInstance };

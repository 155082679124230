import ProfileBubble from "@/components/sections/ProfileBubble";
import Loader from "@/components/ui/Loader";
import PopoverMenu, { PopoverOption } from "@/components/ui/PopoverMenu";
import { useEntityStore } from "@/stores/entityStore";
import { profilePageUiStore } from "@/stores/profilePageUiStore";
import { uiStore } from "@/stores/uiStore";
import { EntityType, ProfilePageSection } from "@/types";
import {
  AcademicCapIcon,
  BanknotesIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  HandRaisedIcon,
  NewspaperIcon,
  PencilIcon,
  PhotoIcon,
  PlusIcon,
  StarIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { useRouter } from "next/compat/router";
import { createElement } from "react";
import { twJoin } from "tailwind-merge";

const Sections = {
  [ProfilePageSection.Highlights]: { icon: StarIcon, title: "Highlights" },
  [ProfilePageSection.About]: { icon: UserIcon, title: "About" },
  [ProfilePageSection.MutualConnections]: { icon: UsersIcon, title: "Mutuals" },
  [ProfilePageSection.ForYou]: { icon: PencilIcon, title: "For You" },
  [ProfilePageSection.Stats]: { icon: ChartBarIcon, title: "Stats" },
  [ProfilePageSection.AuthoredMedia]: { icon: BookOpenIcon, title: "Authored Media" },
  [ProfilePageSection.MediaCoverage]: { icon: NewspaperIcon, title: "Media Coverage" },
  [ProfilePageSection.WorkHistory]: { icon: BriefcaseIcon, title: "Work History" },
  [ProfilePageSection.OtherExperience]: {
    icon: DocumentDuplicateIcon,
    title: "Other Exp",
  },
  [ProfilePageSection.Investments]: { icon: BanknotesIcon, title: "Investments" },
  [ProfilePageSection.People]: { icon: UsersIcon, title: "People" },
  [ProfilePageSection.Funding]: { icon: HandRaisedIcon, title: "Funding" },
  [ProfilePageSection.Volunteering]: { icon: HandRaisedIcon, title: "Volunteering" },
  [ProfilePageSection.Education]: { icon: AcademicCapIcon, title: "Education" },
  [ProfilePageSection.SocialMedia]: { icon: ChatBubbleLeftRightIcon, title: "Other profiles" },
  [ProfilePageSection.PhotosVideos]: { icon: PhotoIcon, title: "Photos & Videos" },
  [ProfilePageSection.Connections]: { icon: UsersIcon, title: "Connections" },
  [ProfilePageSection.Sources]: { icon: BookOpenIcon, title: "Sources" },
};

export default function EntityTOC() {
  const entityStore = useEntityStore();
  const allSections = Object.keys(Sections) as ProfilePageSection[];
  const visibleSections = useStore(entityStore.profileSections);
  const relationshipSections = useStore(entityStore.relationshipSections);
  const router = useRouter();
  const entity = useStore(entityStore.entity);
  const canEdit = useStore(entityStore.canEdit);

  const sectionItems = allSections
    .filter((s) => visibleSections[s])
    .map((section: ProfilePageSection) => ({
      id: section,
      ...Sections[section],
      count: visibleSections[section],
    }));
  const selected = useStore(entityStore.currentSection);

  // If those sections are not visible on the profile, they can potentially be
  // added.
  const personAddableSections = [
    ProfilePageSection.WorkHistory,
    ProfilePageSection.Investments,
    ProfilePageSection.Education,
    ProfilePageSection.Volunteering,
  ].filter((s) => !visibleSections[s]);
  const companyAddableSections: ProfilePageSection[] = [];

  const addableSections =
    entity.type === EntityType.Person ? personAddableSections : companyAddableSections;

  const sectionOptionClass =
    "flex items-center space-x-2 hover:bg-brand-50 rounded-md p-2 text-gray-500";

  return (
    <div className="hidden lg:block">
      <ProfileBubble className="p-2 w-48 sticky top-4">
        <ul>
          {sectionItems.map((section) => (
            <li key={section.id}>
              <a
                href={"#" + section.id}
                className={twJoin(
                  sectionOptionClass,
                  section.id == selected && "bg-brand-50 text-brand-700 font-medium",
                )}
                onClick={() => {
                  entityStore.currentSection.set(section.id);
                }}
              >
                <section.icon className="h-4 w-4" />
                <span className="flex-1">{section.title}</span>
                {section.count == "loading" ?
                  <Loader className="w-3 h-3" />
                : typeof section.count === "number" ?
                  <span className="text-gray-400">{section.count}</span>
                : null}
              </a>
            </li>
          ))}
        </ul>
        {addableSections.length > 0 && canEdit && (
          <PopoverMenu
            buttonClass={twJoin(sectionOptionClass, "w-full")}
            buttonLabel={
              <div className="flex gap-2 items-center">
                <PlusIcon className="h-4 w-4" />
                <span className="flex-1">Add section</span>
              </div>
            }
            popperOptions={{ placement: "auto-start" }}
          >
            {addableSections.map((section) => (
              <PopoverOption
                key={section}
                className={twJoin(sectionOptionClass, "gap-2 px-2")}
                onClick={() => {
                  profilePageUiStore.beginAddingProfileSection(section);
                  router?.push("#" + section);
                }}
              >
                {createElement(Sections[section].icon, { className: "w-4 h-4" })}{" "}
                {Sections[section].title}
              </PopoverOption>
            ))}
          </PopoverMenu>
        )}
      </ProfileBubble>
    </div>
  );
}

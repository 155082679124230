import Head from "next/head";

export default function DefaultMetaTags() {
  return (
    <Head>
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property="og:title" content="Distill - A Smarter Way to Connect" key="og-title" />
      <meta
        property="og:description"
        content="Discover everything you need to know about a person or company in one place. Distill uses AI to create rich profiles, making your connections smarter."
        key="og-description"
      />
      <meta property="og:image" content="/images/social-preview.png" key="og-image" />
      <meta property="og:url" content="https://distill.fyi" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Distill" />

      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Distill - A Smarter Way to Connect" key="twitter-title" />
      <meta
        name="twitter:description"
        content="Discover everything you need to know about a person or company in one place. Distill uses AI to create rich profiles, making your connections smarter."
        key="twitter-description"
      />
      <meta name="twitter:image" content="/images/social-preview.png" key="twitter-image" />
      <meta name="twitter:site" content="@Distill_fyi" />

      {/* <!-- Additional Meta Tags --> */}
      <meta
        name="description"
        content="Discover everything you need to know about a person or company in one place. Distill uses AI to create rich profiles, making your connections smarter."
        key="description"
      />
    </Head>
  );
}

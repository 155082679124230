"use client";

import errorTracker from "@/lib/errorTracker";

// While nextJS provides some polyfills, it does not do it for features
// introduced past a certain cut-off. This is why we need to import them.
import "core-js/features/array/to-reversed";
import "core-js/features/array/to-sorted";
import "core-js/features/array/to-spliced";

import { ReactNode, useEffect, useMemo } from "react";

import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";

import { Provider as RollbarProvider } from "@rollbar/react";
import posthog from "posthog-js";
import Rollbar from "rollbar";

import PostHogWrapper from "@/components/analytics/PostHogWrapper";
import { SidebarProvider } from "@/components/providers/SidebarProvider";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { GlobalSWRConfig } from "@/hooks/swr";
import { useUI } from "@/hooks/useUIStore";
import eventTracker from "@/lib/trackers/eventTracker";
import { createHyperDXBrowser } from "@/lib/trackers/hyperdx";
import { getPostHogApiKey, posthogClientConfig } from "@/lib/trackers/posthog";
import { clientConfig } from "@/lib/trackers/rollbar";
import { WorkspaceData } from "@/types";

// This is the root component of the client side of the application. It will
// be instantiated on all pages, and everything will be rendered inside of it.
export default function ClientRoot({
  children,
  session,
  workspaceData,
}: {
  children: ReactNode;
  session: Session | null;
  workspaceData?: WorkspaceData;
}) {
  const rollbar = useMemo(() => new Rollbar(clientConfig), []);
  const hyperDX = useMemo(() => createHyperDXBrowser(), []);

  useEffect(() => {
    errorTracker.rollbar = rollbar;
    errorTracker.hyperDX = hyperDX;

    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      errorTracker.sendError(event.reason, {
        unhandledPromiseRejection: true,
      });
    };

    const errorHandler = (event: ErrorEvent) => {
      errorTracker.sendError(event.error, {
        windowError: true,
      });
    };

    window.addEventListener("unhandledrejection", unhandledRejectionHandler);
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("unhandledrejection", unhandledRejectionHandler);
      window.removeEventListener("error", errorHandler);
    };
  }, [rollbar, hyperDX]);

  const postHogApiKey = useMemo(() => getPostHogApiKey(), []);
  useEffect(() => {
    if (postHogApiKey) {
      posthog.init(postHogApiKey, posthogClientConfig);
      eventTracker.posthogJS = posthog;
    }
  }, [postHogApiKey]);

  return (
    <RollbarProvider config={clientConfig}>
      <PostHogWrapper posthog={posthog}>
        <ErrorBoundary>
          <SessionProvider session={session}>
            <GlobalSWRConfig>
              {workspaceData && <UIInitializer workspaceData={workspaceData} />}
              <SidebarProvider>{children}</SidebarProvider>
            </GlobalSWRConfig>
          </SessionProvider>
        </ErrorBoundary>
      </PostHogWrapper>
    </RollbarProvider>
  );
}

function UIInitializer({ workspaceData }: { workspaceData: WorkspaceData }) {
  // In App Router contexts, ClientRoot will receive workspace data through
  // the prop, while in Pages Router contexts, it will be passed on to
  // the components through their props. This means in App Router context
  // we can initialize ui globally, while in pages router context each page
  // needs to do it separately.

  useUI(workspaceData);
  return null;
}

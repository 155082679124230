import type { Session } from "next-auth";
import type { AppProps } from "next/app";

// The imports are copied between here and app/layout.tsx, due to NextJS
// requirements of css to be only imported in those two files.
import "@/styles/globals.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";

import ClientRoot from "@/components/layout/ClientRoot";
import RouteTransition from "@/components/ui/RouteTransition";
import DefaultMetaTags from "@/components/meta/DefaultMetaTags";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <ClientRoot session={session}>
      <RouteTransition>
        <DefaultMetaTags />
        <Component {...pageProps} />
      </RouteTransition>
    </ClientRoot>
  );
}

if (process.env.NODE_ENV === "development") {
  // debugging shortcut
  global.window?.window.addEventListener("keydown", function (event) {
    if (event.key == "F6") {
      debugger;
    }
  });
}

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import AssociateSearchResultRow from "@/components/search/AssociateSearchResultRow";
import AssociateSearchResults from "@/components/search/AssociateSearchResults";
import Loader from "@/components/ui/Loader";
import errorTracker from "@/lib/errorTracker";
import { prettyError } from "@/lib/miscUtils";
import { emailStore } from "@/stores/emailStore";
import { searchStore } from "@/stores/searchStore";
import { EmailResolutionStatus, EmailSearchResult, EntityType } from "@/types";

export type AssociateAliasSearchProps = {
  alias: string;
  onSelect: (url: string) => Promise<void>;
  suggestedResults?: EmailSearchResult[];
  onClose?: () => void;
  shouldResolveEmail?: boolean;
  autoSearch?: boolean;
};

export default function AssociateAliasSearch({
  alias,
  onSelect,
  suggestedResults,
  onClose,
  shouldResolveEmail = false,
  autoSearch = true,
}: AssociateAliasSearchProps) {
  const [query, setQuery] = useState(alias);
  const [error, setError] = useState<string>();
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggested, setSuggested] = useState<EmailSearchResult[] | undefined>(suggestedResults);

  const searching = useStore(searchStore.searching) || loading;
  const searchResults = useStore(searchStore.entities);

  const isEmail = alias.includes("@");

  const noResults = searchResults.length === 0 && hasSearched && !searching;

  const resolveEmail = useCallback(async () => {
    if (isEmail) {
      const res = await emailStore.resolveEmail({ address: alias }, "search");
      if (res?.status === EmailResolutionStatus.CANDIDATE) {
        setSuggested(res.candidates);
      }
    }
  }, [alias, isEmail]);

  useEffect(() => {
    setQuery(alias);
    searchStore.reset();
    if (autoSearch) {
      void onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldResolveEmail) {
      void resolveEmail();
    }
  }, [alias, resolveEmail, shouldResolveEmail]);

  if (!alias || !onSelect) {
    return null;
  }

  const clearError = () => {
    setError(undefined);
  };

  const handleSelect = async (url: string) => {
    clearError();
    try {
      await onSelect(url);
      onClose?.();
    } catch (e) {
      setError(`Sorry, can't load this profile: ${prettyError(e)}. We'll look into it!`);
      errorTracker.sendError(e, { source: "associate-alias-search" });
    }
  };

  const onSearch = async () => {
    clearError();
    // If query is a URL, just call handleSelect with it
    if (query.startsWith("http://") || query.startsWith("https://")) {
      setLoading(true);
      try {
        await handleSelect(query);
      } catch (e: unknown) {
        setError(prettyError(e));
      } finally {
        setLoading(false);
      }
      return;
    }
    searchStore
      .searchAll({ query, type: query.includes("@") || isEmail ? "people" : "all", page: 0 })
      .catch((e: unknown) => setError(prettyError(e)))
      .finally(() => {
        setHasSearched(true);
      });
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-2">
        <p className="text-sm text-brand-600">{alias}</p>
        <div className="flex gap-2">
          <span className="relative w-full">
            <MagnifyingGlassIcon className="absolute w-5 h-5 text-gray-400 left-2 top-2" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  void onSearch();
                }
              }}
              className="w-full h-full rounded-md border-0 bg-white py-1.5 pl-8 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </span>
          <button
            onClick={onSearch}
            className={twJoin(
              "bg-brand-600 text-white px-4 py-2 rounded-md",
              searching && "opacity-50 cursor-not-allowed",
            )}
            disabled={searching}
          >
            Search
          </button>
        </div>
        {error && <p className="text-xs text-red-500">{prettyError(error)}</p>}
        <p className="text-sm text-gray-500">
          <b>Hint:</b> Add a link to their <b>LinkedIn</b> or <b>Distill</b> profile above to
          immediately find the right profile
        </p>
      </div>
      {suggested && suggested.length > 0 && (
        <div>
          <p className="text-sm font-semibold mb-2">Suggested</p>
          {suggested.map((entity) => {
            const { url, profileImage: imageUrl, summary: description, type, ...rest } = entity;
            return (
              <AssociateSearchResultRow
                key={url}
                foundEntity={{
                  ...rest,
                  pageUrl: url,
                  displayUrl: url,
                  entityUrl: url,
                  type: type || EntityType.Unknown,
                  imageUrl,
                  description,
                }}
                onSelect={handleSelect}
              />
            );
          })}
        </div>
      )}
      {searching ?
        <div className="flex justify-center w-full h-full align-middle items-center">
          <Loader />
        </div>
      : <AssociateSearchResults onSelect={handleSelect} hideCompanies={isEmail} />}
      {noResults && (
        <div className="flex flex-col h-full items-center justify-center gap-4 py-8">
          <Image
            src="/images/plant.svg"
            width={150}
            height={210}
            alt="plant"
            priority
            className=""
          />{" "}
          <p className="text-sm text-gray-500">Uh oh! No results found.</p>
        </div>
      )}
    </div>
  );
}

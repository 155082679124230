import { entityUrl } from "@/utils/entityUtils";
import { twMerge } from "tailwind-merge";
import Link from "next/link";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { useRouter } from "next/compat/router";
interface Props {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  border?: boolean;
}

export default function Card({ border, href, onClick, children }: Props) {
  const router = useRouter();
  return (
    <div
      onClick={(e: React.MouseEvent) => {
        if (onClick) {
          onClick?.(e);
        } else if (href) {
          router?.push(href);
        }
      }}
    >
      <div className="h-full w-full">
        <div
          className={twMerge(
            "rounded-md relative border-gray-100 bg-white w-full h-full p-2",
            border && "border",
            `cursor-pointer hover:bg-blue-100`,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

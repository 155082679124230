import { atom } from "nanostores";
import { toast } from "react-toastify";

import API from "@/client/api";
import extensionScraper from "@/client/extensionScraper";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import eventTracker from "@/lib/trackers/eventTracker";
import { cleanBaseUrl, prettyError, prettyUrl } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import { EntityType, LinkWithDescription } from "@/types";
import { entityUrl } from "@/utils/entityUtils";

const logger = loggerWithPrefix("[searchStore]");

export type FoundEntity = {
  name: string;
  imageUrl?: string;
  description?: string;
  pageUrl: string;
  displayUrl: string;
  entityUrl: string;
  degree?: string;
  insight?: string;
  location?: string;
  followers?: string;
  type: EntityType;
};

type SearchSource = "web" | "li" | "dst";

class SearchStore {
  results = atom<LinkWithDescription[] | undefined>(undefined);

  entities = atom<FoundEntity[]>([]);

  totalResults = atom<string | undefined>();

  searching = atom<boolean>(false);

  hasMoreCompanies = atom<boolean>(true);

  hasMorePeople = atom<boolean>(true);

  query = atom<string>("");

  peoplePage = atom<number>(0);
  companiesPage = atom<number>(0);

  // --- actions

  reset = () => {
    this.results.set(undefined);
    this.entities.set([]);
    this.searching.set(false);
    this.totalResults.set(undefined);
    this.query.set("");
    this.peoplePage.set(0);
    this.companiesPage.set(0);
  };

  // eslint-disable-next-line @typescript-eslint/max-params
  searchAll = async ({
    query,
    type = "all",
    page = 0,
  }: {
    query: string;
    type?: "all" | "companies" | "people";
    page?: number;
  }) => {
    this.searching.set(true);
    const user = uiStore.user.get();
    if (user) await extensionScraper.init(user);
    logger.info("searching", query, type, page);
    this.query.set(query);

    eventTracker.capture("search-all", {
      query,
      type,
      page,
    });

    const googLinkedinQuery =
      type == "all" ? `${query} (site:linkedin.com/in OR site:linkedin.com/company)`
      : type == "companies" ? `${query} site:linkedin.com/company`
      : `${query} site:linkedin.com/in`;

    if (type == "people") this.peoplePage.set(page || 0);
    if (type == "companies") this.companiesPage.set(page || 0);

    // this logic will wait up to 3 seconds for the other promises to resolve before updating the list
    const allResults: { source: SearchSource; results: FoundEntity[] }[] = [];

    let allFinished = false;
    const updateResults = (results: FoundEntity[], source: SearchSource) => {
      allResults.push({ source, results });
      setTimeout(() => {
        if (allFinished) return;
        this.addEntities(results, source);
      }, 3000);
    };

    const promises = [
      this.searchLinkedin(query, {
        q: query,
        type,
        page: page || 0,
      }).then((results) => updateResults(results, "li")),
      this.searchWeb(query, [
        { q: googLinkedinQuery, page: page ? page + 1 : 0 },
        { q: query, page: page ? page + 1 : 0 },
      ]).then((results) => updateResults(results, "web")),
      this.searchEntities(query, page).then((results) => updateResults(results, "dst")),
    ];
    await Promise.all(promises);

    allFinished = true;
    allResults.forEach((r) => this.addEntities(r.results, r.source));
    this.searching.set(false);
  };

  loadMore = async (type: "companies" | "people") => {
    if (this.searching.get()) return;
    this.searching.set(true);
    if (type == "companies") this.hasMoreCompanies.set(false);
    else if (type == "people") this.hasMorePeople.set(false);

    const page = type == "companies" ? this.companiesPage.get() : this.peoplePage.get();
    await this.searchAll({
      query: this.query.get(),
      type,
      page: page + 1,
    });

    this.searching.set(false);
  };

  searchLinkedin = async (
    origQuery: string,
    query: {
      q: string;
      type: "all" | "companies" | "people";
      page: number;
    },
  ) => {
    const data = await extensionScraper.searchLi(query);
    logger.info("li search", query.q, query.page, "returned", data);

    const entities: FoundEntity[] = [];
    if (this.query.get() != origQuery) return entities;

    data.results.forEach((r) => {
      try {
        const url = new URL(r.url);
        const pathname = url.pathname.replace(/\/$/, "");

        entities.push({
          ...r,
          imageUrl: r.profileImage,
          description: r.title,
          pageUrl: r.url,
          displayUrl: `linkedin.com${pathname}`,
          entityUrl: r.url,
        });
      } catch (e) {
        errorTracker.sendError(e, { url: r.url });
      }
    });

    if (query.type != "people") {
      this.hasMoreCompanies.set(
        entities.filter((e) => e.type == EntityType.Company).length >=
          (query.type == "all" ? 3 : 10),
      );
    }
    if (query.type != "companies") {
      this.hasMorePeople.set(entities.filter((e) => e.type == EntityType.Person).length >= 10);
    }

    return entities;
  };

  searchWeb = async (origQuery: string, queries: { q: string; page: number }[]) => {
    const allResults: LinkWithDescription[] = [];
    const linkSet = new Set<string>();

    const entities: FoundEntity[] = [];
    await Promise.all(
      queries.map(async (query) => {
        const data = await extensionScraper.searchWeb(query);
        logger.info("web search", query.q, query.page, "returned", data);

        if (this.query.get() != origQuery) return;
        if (!data) return;

        const uniqueLinks = data.filter((d) => {
          if (linkSet.has(d.url)) return false;
          linkSet.add(d.url);
          return true;
        });

        allResults.push(...uniqueLinks);
        this.results.set(allResults);
        entities.push(...(await this.findEntities(uniqueLinks)));
      }),
    );

    return entities;
  };

  searchEntities = async (q: string, page?: number) => {
    const data = await API.searchEntitiesFull({ query: q, page });

    const foundEntities: FoundEntity[] = data.map((e) => ({
      name: e.name,
      imageUrl: e.imageUrl?.includes("static.licdn.com") ? undefined : e.imageUrl || undefined,
      pageUrl: e.url,
      displayUrl: prettyUrl(e.url),
      entityUrl: e.url,
      type: e.type,
    }));

    return foundEntities;
  };

  findEntities = async (results: LinkWithDescription[]) => {
    const entities: FoundEntity[] = [];

    const seen = new Set<string>();
    logger.info("finding entities from results", results);
    await Promise.all(
      results.map(async (r) => {
        if (!r.title) return;
        try {
          const url = new URL(r.url);
          const pathname = url.pathname.replace(/\/$/, "");

          const baseUrl = url.hostname.replace("www.", "");
          const imageUrl = undefined;

          const cleanedUrl = cleanBaseUrl(r.url);
          if (seen.has(cleanedUrl)) return;
          seen.add(cleanedUrl);

          if (url.hostname.includes("linkedin.com") && pathname.startsWith("/in/")) {
            if (pathname.substring(4).includes("/")) return;
            entities.push({
              imageUrl,
              name: r.title,
              description: r.description,
              pageUrl: r.url,
              displayUrl: `linkedin.com${pathname}`,
              entityUrl: r.url,
              type: EntityType.Person,
            });
          } else if (url.hostname.includes("linkedin.com") && pathname.startsWith("/company/")) {
            if (pathname.substring(9).indexOf("/") > 0) return;
            entities.push({
              imageUrl,
              name: r.title,
              description: r.description,
              pageUrl: r.url,
              displayUrl: `linkedin.com${pathname}`,
              entityUrl: r.url,
              type: EntityType.Company,
            });
          } else if (
            !seen.has(baseUrl) &&
            (!pathname || pathname == "/") &&
            !domainBlacklist.find((b) => url.hostname.includes(b)) &&
            baseUrl.indexOf(".") == baseUrl.lastIndexOf(".")
          ) {
            const companyCheck = await API.companyCheck({
              url: r.url,
              title: r.title,
              description: r.description ?? "",
            });

            if (companyCheck.linkedinUrl || companyCheck.aiCompanyClassification) {
              entities.push({
                name: r.title,
                imageUrl,
                description: r.description,
                pageUrl: r.url,
                displayUrl: baseUrl,
                entityUrl: r.url,
                type: EntityType.Company,
              });
            }
          }
        } catch (e) {
          toast.error(prettyError(e));
          errorTracker.sendError(e, { url: r.url });
        }
      }),
    );

    return entities;
  };

  addEntities = (entities: FoundEntity[], source: SearchSource) => {
    const existing = this.entities.get();
    const existingByUrl: Record<string, FoundEntity> = {};

    const newEntities: FoundEntity[] = [];

    // slot linkedin results before web and internal, as they are ranked more intelligently
    const allEntities = source == "li" ? [...entities, ...existing] : [...existing, ...entities];

    allEntities.forEach((e) => {
      const urlKey = cleanBaseUrl(e.pageUrl);
      if (existingByUrl[urlKey]) {
        Object.assign(
          existingByUrl[urlKey],
          Object.fromEntries(Object.entries(e).filter(([_, v]) => !!v)),
        );
      } else {
        newEntities.push(e);
        existingByUrl[urlKey] = e;
      }
    });

    const people = newEntities.filter((r) => r.type == EntityType.Person);
    const companies = newEntities.filter((r) => r.type == EntityType.Company);

    this.entities.set([...people, ...companies]);
  };

  pendingRequests = new Set<string>();
  createEntity = async (
    entity: { entityUrl: string; name: string; type?: EntityType },
    navigateToUrl?: boolean,
  ) => {
    if (this.pendingRequests.has(entity.entityUrl)) {
      return;
    }
    this.pendingRequests.add(entity.entityUrl);

    eventTracker.capture("search-create-entity", { name: entity.name });

    try {
      const resolved = await extensionScraper.createEntity(entity.entityUrl, {
        name: entity.name,
        type: entity.type,
      });
      logger.info("resolved:", resolved);
      const skip = new URLSearchParams(location.search).get("skip");
      const url = entityUrl(resolved) + (skip ? "?skip=" + skip : "");
      if (url && navigateToUrl) uiStore.routeTo(url);
      return url;
    } catch (e: unknown) {
      errorTracker.sendError(e, { source: "search-create-entity" });
      throw e;
    }
  };

  addToList = async (entity: FoundEntity) => {};
}

export const searchStore = new SearchStore();

function followersToNum(followers: string | undefined) {
  if (!followers) return 0;
  return parseInt(followers.replace(/,/g, "").replace("K", "000").replace("M", "000000"));
}

const domainBlacklist = [
  `techcrunch.com`,
  `crunchbase.com`,
  `producthunt.com`,
  `wikipedia.org`,
  `twitter.com`,
  `facebook.com`,
  `linkedin.com`,
  `youtube.com`,
  `craft.co`,
  `instagram.com`,
  `medium.com`,
  `capterra.com`,
  `getapp.com`,
  `g2.com`,
  `softwareadvice.com`,
  `.org`,
  `.edu`,
  `google.com`,
  `zapier.com`,
  `ycombinator.com`,
  `owler.com`,
  `glassdoor.com`,
  `angel.co`,
  `bloomberg.com`,
  `forbes.com`,
  `businessinsider.com`,
  `inc.com`,
  `bbb.org`,
  `pitchbook.com`,
  `f6s.com`,
  `dribbble.com`,
  `behance.net`,
  `vimeo.com`,
  `reddit.com`,
  `quora.com`,
  `tumblr.com`,
  `pinterest.com`,
  `github.com`,
  `kickstarter.com`,
  `indiegogo.com`,
  `.gov`,
  `manta.com`,
  `buzzfeed.com`,
  `theguardian.com`,
  `nytimes.com`,
  `wired.com`,
  `techradar.com`,
  `thenextweb.com`,
  `mashable.com`,
  `rocketreach.co`,
  `tracxn.com`,
  `theorg.com`,
  `globalventuring.com`,
];

const urlBlacklist = [
  `/company`,
  `/profile`,
  `/team`,
  `/user`,
  `/people`,
  `/reviews`,
  `/careers`,
  `/jobs`,
  `/press`,
  `/blog`,
  `/posts`,
  `/browse`,
  `dictionary`,
  `directory`,
  ".htm",
];

import { getCompanyConfig } from "@/components/experiences/CompanyConfig";
import {
  calculateDateString,
  formatDate,
  parseEmploymentTypeFromCompany,
} from "@/components/experiences/utils";
import useEntityCardData from "@/components/hooks/useEntityCardData";
import ConditionalLink from "@/components/ui/ConditionalLink";
import DelimitedList from "@/components/ui/DelimitedList";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useEntitySidebarClick } from "@/hooks/useEntitySidebarClick";
import { formatNumber, smartTruncate, toAbbreviatedDollarAmount } from "@/lib/stringUtils";
import {
  BaseRelationshipData,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { CompanyCardData, EntityType, ProfilePageSection } from "@/types";
import { isEntityLinkable, maybeEntityUrl } from "@/utils/entityUtils";
import { ReactElement, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  firstPosition: Partial<RelationshipWithEntity>;
  lastPosition: Partial<RelationshipWithEntity>;
  useFirstPositionDescription?: boolean;
  pageSection: ProfilePageSection;
  editMode: boolean;
  titleButtons?: ReactElement;
}

const TRUNCATE_DESC_LENGTH = 300;

export default function CompanyHeader({
  firstPosition,
  lastPosition,
  useFirstPositionDescription,
  pageSection,
  editMode,
  titleButtons,
}: Props) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const companyConfig = getCompanyConfig({
    workExperience: firstPosition,
    companyType: parseEmploymentTypeFromCompany(firstPosition),
    companyEntity: firstPosition.to,
  });

  const { data, isLoading: factsLoading } = useEntityCardData({
    entityId: firstPosition.to?.id,
  });

  const cardData = (data || { entity: firstPosition.to }) as CompanyCardData;

  let description = companyConfig.description;
  if (useFirstPositionDescription) {
    const date = firstPosition.startedDate && formatDate(firstPosition.startedDate);
    // TODO: Originally title was included here, but investments don't have it
    const shortDescription = smartTruncate(
      (firstPosition.data as BaseRelationshipData)?.description || "",
      100,
    );
    description = [date, shortDescription].filter(Boolean).join(" · ");
  }

  // Keep in mind they usually come reverse chronologically.
  // TODO: Sort chronologically instead of relying on ordering?
  const companyDates = calculateDateString(lastPosition, firstPosition);

  const companyDatesElement =
    Array.isArray(companyDates) ? <DelimitedList list={companyDates} /> : companyDates;

  const companyName = cardData?.entity?.name || firstPosition.toName;
  const isCompanyLinkable = isEntityLinkable(cardData?.entity);
  const companyLink =
    !isCompanyLinkable ? undefined : (
      maybeEntityUrl(cardData?.entity) ||
      (companyName ? `/search?q=${encodeURIComponent(companyName)}` : undefined)
    );

  const companyClick = useEntitySidebarClick(cardData?.entity?.id, cardData?.entity?.url);

  const isEducation = pageSection === ProfilePageSection.Education;

  const details = [
    cardData?.employees && `${formatNumber(cardData?.employees)} Employees`,
    (!isEducation && cardData?.totalFundsRaised && `Funding: ${cardData?.totalFundsRaised}`) ||
      (cardData?.latestRoundSize &&
        `Last Round: ${toAbbreviatedDollarAmount(Number(cardData?.latestRoundSize))}`),
    cardData?.founded && `Founded: ${cardData?.founded}`,
    !isEducation && cardData?.industry && `Industry: ${cardData?.industry}`,
    cardData?.location,
  ].filter(Boolean);

  const detailsElement = (
    <div className="mt-2 sm:mt-0 flex flex-wrap text-gray-400 leading-4 text-13pt">
      <DelimitedList list={details} itemClass="whitespace-nowrap line-height " />
    </div>
  );

  return (
    <div>
      <div className="flex items-center">
        <ConditionalLink
          href={companyLink}
          data-tooltip-id="tooltip"
          data-tooltip-content={
            cardData && isCompanyLinkable ? `See ${cardData.entity?.name}'s profile` : undefined
          }
          onClick={isCompanyLinkable ? companyClick : undefined}
        >
          <EntityIconWithPlaceholder
            entity={{
              id: cardData?.entity?.id || firstPosition.id,
              name: companyName || firstPosition.type,
              imageUrl:
                (
                  cardData?.entity?.imageUrl &&
                  !cardData?.entity?.imageUrl?.includes("static.licdn.com")
                ) ?
                  cardData?.entity?.imageUrl
                : firstPosition.toImageUrl,
              fallbackImageUrl: firstPosition.toImageUrl,
              url: cardData?.entity?.url,
              type: EntityType.Company,
            }}
            className="h-10 w-10 mt-[2px] mr-4 border border-gray-100"
            customIcon={companyConfig.icon}
          />
        </ConditionalLink>
        <div className="flex flex-col gap-1.5 mt-auto mb-auto">
          <ConditionalLink
            href={companyLink}
            onClick={isCompanyLinkable ? companyClick : undefined}
          >
            <div className="flex flex-row items-center flex-wrap">
              <h2
                className={twMerge(
                  "text-base font-semibold mr-2 leading-none h-5 flex items-center",
                  !!companyLink ? "hover:underline cursor-pointer" : "",
                )}
              >
                {companyConfig.name.replace("\\'", "'")}
              </h2>
              <span
                className="text-xs text-gray-400 no-underline"
                {...(pageSection === ProfilePageSection.Investments ?
                  {
                    "data-tooltip-id": "tooltip",
                    "data-tooltip-content": "Investment Date",
                    "data-tooltip-place": "bottom",
                  }
                : {})}
              >
                {pageSection === ProfilePageSection.Investments ?
                  firstPosition.startedDate && formatDate(firstPosition.startedDate)
                : companyDatesElement}
              </span>
              <div
                className="flex-shrink-0 ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {titleButtons}
              </div>
            </div>
          </ConditionalLink>
          {details && <div className="hidden sm:flex ">{detailsElement}</div>}
        </div>
      </div>
      {details && <div className="flex sm:hidden ">{detailsElement}</div>}
      {description && (
        <div>
          <div className="mt-2 text-sm sm:ml-14 sm:mt-1">
            {editMode || showFullDescription ?
              description
            : smartTruncate(description, TRUNCATE_DESC_LENGTH)}
            {description.length > TRUNCATE_DESC_LENGTH && !showFullDescription && (
              <span
                className="ml-2 text-brand-500 cursor-pointer"
                onClick={() => setShowFullDescription(true)}
              >
                see more
              </span>
            )}
          </div>
          <div className="h-[8px]" />
        </div>
      )}
    </div>
  );
}

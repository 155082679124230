import { UIMessage } from "@/types";

import ChatAvatar from "@/components/ui/ChatAvatar";
import { UserWithMeta } from "@/types";
import { memo, useMemo } from "react";

interface ChatMessageProps {
  message: UIMessage;
  user: UserWithMeta | null;
}

const ChatMessage = memo(function ChatMessage({ message, user }: ChatMessageProps) {
  const formattedTime = useMemo(() => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(message.createdAt);
  }, [message.createdAt]);

  return (
    <div className="flex items-start space-x-3 mb-4 group">
      <ChatAvatar role={message.role} user={user} />
      <div className="flex min-h-[32px] items-center flex-1">
        <div className="flex-1 whitespace-pre-wrap">
          {typeof message.content === "string" ? formatText(message.content) : message.content}
        </div>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity text-xs text-gray-400 ml-1">
          {formattedTime}
        </div>
      </div>
    </div>
  );
});

export default ChatMessage;

const formatText = (text: string) => {
  return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
    if (part.startsWith("**") && part.endsWith("**")) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
};

import SearchBox from "@/components/search/SearchBox";
import SettingsButton from "@/components/settings/SettingsButton";
import { LoggedOutButtons } from "@/components/ui/LoggedOutButtons";
import Logo from "@/components/ui/Logo";
import VisitLimitIcon from "@/components/visitLimit/VisitLimitIcon";
import { uiStore } from "@/stores/uiStore";
import {
  COLOR_ACTIVABLE,
  COLOR_ACTIVE,
  COLOR_INACTIVE,
  defaultPageWidthClass,
} from "@/styles/constants";
import { FeatureFlag } from "@/types";
import { ClockIcon } from "@heroicons/react/20/solid";
import { GlobeAmericasIcon } from "@heroicons/react/24/outline";
import { HomeIcon, QueueListIcon } from "@heroicons/react/24/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

export default function Navbar({
  initialQuery,
  noSearch,
  titleButtons,
}: {
  initialQuery?: string;
  noSearch?: boolean;
  titleButtons?: React.ReactNode;
}) {
  const user = useStore(uiStore.user);
  const showDiscover = useFeatureFlagEnabled(FeatureFlag.DiscoverTab);

  return (
    <nav className="py-2 px-2 sm:px-6 h-[56px] flex gap-4 justify-between border-b shadow-sm bg-white">
      <div
        className={twJoin(
          defaultPageWidthClass,
          "flex gap-x-2 sm:gap-x-6 items-center justify-between",
        )}
      >
        <Link href={user ? "/dashboard" : "/"}>
          <Logo className="max-w-8" />
        </Link>
        {user && !noSearch && (
          <div className="flex-1 mx-4">
            <SearchBox
              initialQuery={initialQuery}
              submitButton
              className="max-w-72 lg:max-w-96"
              magnifyClassName="left-4"
              placeholder="Search for a person or company"
            />
          </div>
        )}
        {user && (
          <div className="hidden sm:flex space-x-1 text-gray-500">
            <NavLink href="/dashboard" tooltip="Your dashboard">
              <HomeIcon className="w-4 h-4 mr-2 hidden md:inline" /> Home
            </NavLink>
            <NavLink href="/recents" tooltip="Recently viewed people and companies">
              <ClockIcon className="w-4 h-4 mr-2 hidden md:inline" /> Recents
            </NavLink>
            <NavLink href="/lists" tooltip="Create and view lists">
              <QueueListIcon className="w-4 h-4 mr-2 hidden md:inline" /> Lists
            </NavLink>
            {showDiscover && (
              <NavLink href="/discover" tooltip="Discover new people and companies">
                <GlobeAmericasIcon className="w-4 h-4 mr-2 hidden md:inline" /> Discover
              </NavLink>
            )}
          </div>
        )}
        <div className="flex gap-x-2 items-center">
          {titleButtons}
          <VisitLimitIcon />
          {user ?
            <SettingsButton />
          : <LoggedOutButtons />}
        </div>
      </div>
    </nav>
  );
}

export const NavLink = ({
  href,
  onClick,
  children,
  tooltip,
}: {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
  tooltip?: string;
}) => {
  const [effect, setEffect] = useState(false);
  const pathname = usePathname();
  const baseNavClass = "p-2 rounded-md";

  return (
    <Link
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltip}
      className={twJoin(
        pathname === href ? COLOR_ACTIVE : COLOR_INACTIVE,
        COLOR_ACTIVABLE,
        "flex items-center font-medium",
        baseNavClass,
        effect && "animate-singlePulse",
      )}
      onClick={(e) => {
        setEffect(true);
        onClick?.(e);
      }}
      onAnimationEnd={() => setEffect(false)}
      href={href}
    >
      {children}
    </Link>
  );
};

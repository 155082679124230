import CompanyStats from "@/components/companies/CompanyStats";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import PastExperiences, { InvestmentList } from "@/components/experiences/PastExperiences";
import AboutSection from "@/components/sections/AboutSection";
import AuthoredMedia from "@/components/sections/AuthoredMedia";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeopleSection from "@/components/sections/CompanyPeopleSection";
import EntityHeaderV1 from "@/components/sections/EntityHeaderV1";
import EntityHeaderV2 from "@/components/sections/EntityHeaderV2";
import MediaCoverage from "@/components/sections/MediaCoverage";
import MutualConnections from "@/components/sections/MutualConnections";
import SocialProfiles from "@/components/sections/SocialProfiles";
import Sources from "@/components/sections/Sources";
import { useEntityStore } from "@/stores/entityStore";
import { EntityType } from "@/types";
import { useStore } from "@nanostores/react";
import { useFeatureFlagEnabled } from "posthog-js/react";

export default function EntityContent() {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const status = useStore(entityStore.initialStatus);
  const newHeader = useFeatureFlagEnabled("entity-header-v2");
  return (
    <>
      {newHeader ?
        <EntityHeaderV2 status={status} />
      : <EntityHeaderV1 status={status} />}

      <ShowPipelineStatus status={status} />
      <AboutSection />
      {entity.type === EntityType.Person && <MutualConnections />}
      {entity.type === EntityType.Company && <CompanyStats />}
      {entity.type === EntityType.Company && <CompanyPeopleSection />}
      {entity.type === EntityType.Company && <CompanyFunding />}
      {entity.type === EntityType.Person && <AuthoredMedia />}
      <MediaCoverage />
      {entity.type === EntityType.Person && <PastExperiences />}
      {entity.type === EntityType.Company && <InvestmentList />}
      <SocialProfiles />
      <Sources />
    </>
  );
}

import { atom, computed } from "nanostores";
import { useContext } from "react";

import { logger } from "@/lib/logger";
import { uiStore } from "@/stores/uiStore";
import { ChatRole, EntityUIType, MessageAttachments, OpenAIChatMessage, UIMessage } from "@/types";

import API from "@/client/api";
import { EntityLayoutContext } from "@/stores/entityStore";
import type { Types as Ably } from "ably";

export enum MessageStoreType {
  Search = "search",
  Entity = "entity",
}

/** used to render messages. this is used in multiple places */
export class MessageStore {
  type = atom<MessageStoreType>(MessageStoreType.Search);

  // Type specific fields
  entityId = atom<string | undefined>(undefined);

  // --- fields

  // messages, with newest at the front
  messages = atom<UIMessage[]>([]);

  isEmpty = computed([this.messages], (messages) => messages.length === 0);

  inProgress = atom<boolean>(false);

  partialMessage = atom<string | undefined>();

  currentMessage = atom<UIMessage | null>(null);

  error = atom<string | undefined>();

  isLoadingMessages = atom<boolean>(false);

  pagination = atom<{
    cursor: string | undefined;
    hasMore: boolean;
  }>({
    cursor: undefined,
    hasMore: true,
  });

  // --- actions

  subscription: { id: string; channel: Ably.RealtimeChannelCallbacks } | undefined;

  onSendMessage: ((store: MessageStore, content: string) => Promise<void>) | undefined;
  onUpdateMessage:
    | ((store: MessageStore, message: UIMessage, updates: Partial<UIMessage>) => Promise<void>)
    | undefined;
  onDeleteMessage: ((store: MessageStore, message: UIMessage) => Promise<void>) | undefined;

  init = (type: MessageStoreType, entityId?: string) => {
    this.type.set(type);
    this.messages.set([]);
    this.error.set(undefined);
    this.partialMessage.set(undefined);
    this.currentMessage.set(null);
    this.inProgress.set(false);
    this.toDelete = [];

    if (this.subscription) {
      this.subscription.channel.unsubscribe();
      this.subscription = undefined;
    }

    if (type === MessageStoreType.Entity) {
      if (!entityId) throw new Error("Entity ID is required for entity chat");
      this.entityId.set(entityId);
      this.inProgress.set(true);
      void this.loadEntityChatMessages().then(() => this.inProgress.set(false));
    }
  };

  private interruptCallback = () => {};
  interrupt = () => {
    this.interruptCallback();
    this.inProgress.set(false);
    this.partialMessage.set(undefined);
  };

  setCurrentMessageContent = (content: string) => {
    if (!content) {
      this.currentMessage.set(null);
      return;
    }
    let currentMessage = this.currentMessage.get();
    if (!currentMessage) {
      currentMessage = {
        id: new Date().toISOString(),
        content,
        role: ChatRole.User,
        userId: uiStore.user.get()?.id,
        createdAt: new Date(),
      };
    }
    this.currentMessage.set({
      ...currentMessage,
      content,
    });
  };

  sendCurrentMessage = async (force?: boolean) => {
    if (this.inProgress.get()) {
      if (force) {
        this.interrupt();
      } else {
        return;
      }
    }

    const currentMessage = this.currentMessage.get();
    if (!currentMessage || !currentMessage.content) return;
    logger.info("sending message", currentMessage.content);
    this.currentMessage.set(null);
    this.inProgress.set(true);
    this.pushMessage(currentMessage);

    const chatPromise =
      this.onSendMessage?.(this, currentMessage.content || "") || Promise.resolve();

    if (this.toDelete.length) {
      const toDelete = this.toDelete;
      this.toDelete = [];
      toDelete.forEach((m) => {
        void this.deleteMessage(m);
      });
    }

    await chatPromise;
    this.inProgress.set(false);
  };

  lastMessage: UIMessage | undefined;
  lastUserMessage: UIMessage | undefined;

  getHistory = (messages: UIMessage[]) => {
    const history: OpenAIChatMessage[] = messages.map((m) => ({
      role: m.role == ChatRole.Assistant || m.role == ChatRole.User ? m.role : ChatRole.User,
      content: m.content || "",
      function_call: m.attachments?.function_call,
    }));

    let messageBudget = 2000;
    const truncated = history.reduce<OpenAIChatMessage[]>((acc, message) => {
      if (messageBudget < 0) return acc;
      const encodedLength = JSON.stringify(message).length / 3;
      messageBudget -= encodedLength;
      // note that this reverses the order of the messages so oldest is first
      return [message, ...acc];
    }, []);

    return truncated;
  };

  pushMessage = (message: UIMessage | OpenAIChatMessage) => {
    const attachments: MessageAttachments | undefined =
      (message as UIMessage).attachments ||
      ((message as OpenAIChatMessage).function_call ?
        {
          function_call: (message as OpenAIChatMessage).function_call,
        }
      : undefined);

    const uiMessage: UIMessage = {
      createdAt: new Date(),
      userId: uiStore.user.get()?.id,
      attachments,
      ...message,
    };

    this.messages.set([uiMessage, ...this.messages.get()]);
    this.lastMessage = uiMessage;
    if (message.role == ChatRole.User) this.lastUserMessage = uiMessage;

    return uiMessage;
  };

  updateMessage = async (message: UIMessage, updates: Partial<UIMessage>) => {
    if (!message?.id) throw new Error("no message id to update");
    if (this.onUpdateMessage) await this.onUpdateMessage(this, message, updates);
  };

  toDelete: UIMessage[] = [];

  requestEdit = (message: UIMessage) => {
    this.popMessages(message);
    this.currentMessage.set(message);
  };

  popMessages = (target: UIMessage) => {
    const messages = this.messages.get();
    const index = messages.indexOf(target);
    if (index === -1) return [];
    const newMessages = messages.slice(index + 1);
    this.toDelete = messages.slice(0, index + 1);

    // don't update session until we have a new message
    this.messages.set(newMessages);
    return newMessages;
  };

  deleteMessage = async (target: UIMessage) => {
    const messages = this.messages.get();
    this.messages.set(messages.filter((message) => message !== target));
    if (this.onDeleteMessage) await this.onDeleteMessage(this, target);
  };

  hasMoreMessages(): boolean {
    return this.pagination.get().hasMore;
  }

  getLatestMessage(): UIMessage | undefined {
    return this.messages.get()[0];
  }

  async loadEntityChatMessages(limit: number = 10): Promise<void> {
    const currentPagination = this.pagination.get();
    const entityId = this.entityId.get();
    if (this.isLoadingMessages.get() || !currentPagination.hasMore || !entityId) {
      return;
    }

    this.isLoadingMessages.set(true);
    this.error.set(undefined);

    try {
      const response = await API.getEntityChatMessages({
        entityId,
        cursor: currentPagination.cursor,
        limit,
      });

      const mappedMessages = response.messages.map((m) => ({
        id: m.id,
        content: m.content ?? "",
        role: m.role as ChatRole,
        userId: m.userId ?? undefined,
        createdAt: new Date(m.createdAt),
        attachments: m.attachments as MessageAttachments | undefined,
      }));

      const currentMessages = this.messages.get();
      this.messages.set([...currentMessages, ...mappedMessages]);

      this.pagination.set({
        cursor: mappedMessages[mappedMessages.length - 1]?.id,
        hasMore: response.hasMore,
      });
    } catch (error: unknown) {
      this.error.set(error instanceof Error ? error.message : "Failed to load messages");
    } finally {
      this.isLoadingMessages.set(false);
    }
  }

  async exchangeEntityChatMessage(message: string): Promise<void> {
    const entityId = this.entityId.get();
    if (!message.trim() || this.inProgress.get() || !entityId) return;

    this.inProgress.set(true);
    this.error.set(undefined);

    try {
      this.pushMessage({
        id: crypto.randomUUID(),
        content: message.trim(),
        role: "user",
        createdAt: new Date(),
      });

      const response = await API.sendEntityChatMessage({
        entityId,
        message: message.trim(),
      });

      this.pushMessage({
        id: response.id,
        content: response.content,
        role: response.role,
        createdAt: new Date(response.createdAt),
      });
    } catch (error: unknown) {
      this.error.set(error instanceof Error ? error.message : "Something went wrong");
    } finally {
      this.inProgress.set(false);
    }
  }
}

declare global {
  interface Window {
    messageStore: MessageStore;
  }
}

// Original store
export const messageStore = new MessageStore();
if (typeof window !== "undefined") window.messageStore = messageStore;

// Sidebar store
const sidebarStore = new MessageStore();

// Hook to use within components to get the correct store instance
export const useMessageStore = () => {
  const type = useContext(EntityLayoutContext);
  return type === EntityUIType.Sidebar ? sidebarStore : messageStore;
};

import { Fact } from "@prisma/client";
import { Type, type Static } from "@sinclair/typebox";

export const EntityFact = {
  // name, image and title are mirrored to the entity object
  Name: "Name",
  ImageUrl: "ImageUrl",
  Title: "Title",
  Description: "Description",
  About: "About",
} as const;
export type EntityFact = (typeof EntityFact)[keyof typeof EntityFact];

export const PersonFact = {
  Birthday: "Birthday",
  Birthyear: "Birthyear",
  Gender: "Gender",
  Pronouns: "Pronouns",
  Height: "Height",
  AltNames: "AltNames",
  LanguagesSpoken: "LanguagesSpoken",
  Location: "Location",
} as const;
export type PersonFact = (typeof PersonFact)[keyof typeof PersonFact];

export const CompanyFact = {
  Employees: "Employees",
  FoundedYear: "FoundedYear",
  Headquarters: "Headquarters",
  Industry: "Industry",
  CompanySize: "CompanySize",
  HistoricalHeadcount: "HistoricalHeadcount",
  CompanyType: "CompanyType",
  TotalFunding: "TotalFunding",
  TotalFundingRounds: "TotalFundingRounds",
  LatestRound: "LatestRound",
  Founders: "Founders",
  State: "State",
  WebsiteOwnedByDifferentEntity: "WebsiteOwnedByDifferentEntity",
  Website: "Website",

  // VC-specific
  NumFundsRaised: "NumFundsRaised",
  NumInvestments: "NumInvestments",
  NumExits: "NumExits",
  TotalFundsRaisedAmt: "TotalFundsRaisedAmt",
  InvestmentRounds: "InvestmentRounds",
  DoLeadInvestments: "DoLeadInvestments",
  RecentInvestments: "RecentInvestments",
  FamousInvestments: "FamousInvestments",
  InvestmentSectors: "InvestmentSectors",
  InvestmentGeographies: "InvestmentGeographies",
  LatestFundSize: "LatestFundSize",
  LatestFundDate: "LatestFundDate",
  AssetsUnderManagement: "AssetsUnderManagement",
  BrandedBlurb: "BrandedBlurb",
} as const;
export type CompanyFact = (typeof CompanyFact)[keyof typeof CompanyFact];

export type FactType = EntityFact | PersonFact | CompanyFact;
export const FactTypeValidator = Type.Enum({ ...EntityFact, ...PersonFact, ...CompanyFact });

const FactValues = {
  [EntityFact.Name]: Type.String(),
  [EntityFact.ImageUrl]: Type.String(),
  [EntityFact.Title]: Type.String(),
  [EntityFact.About]: Type.String(),
  [EntityFact.Description]: Type.String(),

  // mm-dd
  [PersonFact.Birthday]: Type.String(),
  // yyyy
  [PersonFact.Birthyear]: Type.Number(),
  [PersonFact.Gender]: Type.String(),
  [PersonFact.Pronouns]: Type.String(),
  [PersonFact.Height]: Type.String(),
  [PersonFact.AltNames]: Type.Array(Type.String()),
  [PersonFact.LanguagesSpoken]: Type.Array(Type.String()),
  [PersonFact.Location]: Type.String(),

  [CompanyFact.Employees]: Type.Number(),
  [CompanyFact.FoundedYear]: Type.Number(),
  [CompanyFact.Headquarters]: Type.String(),
  [CompanyFact.Industry]: Type.String(),
  [CompanyFact.CompanySize]: Type.String(),
  [CompanyFact.CompanyType]: Type.String(),
  [CompanyFact.TotalFunding]: Type.String(),
  [CompanyFact.Website]: Type.String(),
  [CompanyFact.Founders]: Type.Array(Type.String()),
  [CompanyFact.State]: Type.Union([
    Type.Literal("ACTIVE"),
    Type.Literal("ACQUIRED"),
    Type.Literal("POSSIBLY_ACQUIRED"),
    Type.Literal("INACTIVE"),
    Type.Literal("POSSIBLY_INACTIVE"),
  ]),
  [CompanyFact.WebsiteOwnedByDifferentEntity]: Type.Boolean(),
  [CompanyFact.HistoricalHeadcount]: Type.Array(
    Type.Object({
      date: Type.String(),
      headcount: Type.Number(),
    }),
  ),

  [CompanyFact.NumFundsRaised]: Type.Number(),
  [CompanyFact.NumInvestments]: Type.Number(),
  [CompanyFact.NumExits]: Type.Number(),
  [CompanyFact.TotalFundsRaisedAmt]: Type.String(),
  [CompanyFact.InvestmentRounds]: Type.Array(Type.String()),
  [CompanyFact.DoLeadInvestments]: Type.Boolean(),
  [CompanyFact.RecentInvestments]: Type.Array(Type.String()),
  [CompanyFact.FamousInvestments]: Type.Array(Type.String()),
  [CompanyFact.InvestmentSectors]: Type.Array(Type.String()),
  [CompanyFact.InvestmentGeographies]: Type.Array(Type.String()),
  [CompanyFact.LatestFundSize]: Type.Number(),
  [CompanyFact.LatestFundDate]: Type.String(), // yyyy-mm-dd
  [CompanyFact.AssetsUnderManagement]: Type.Number(),
  [CompanyFact.BrandedBlurb]: Type.String(),
  [CompanyFact.TotalFundingRounds]: Type.Number(),
  [CompanyFact.LatestRound]: Type.Object({
    moneyRaised: Type.Optional(Type.String()),
    fundingType: Type.Optional(Type.String()),
    announcedDate: Type.Optional(
      Type.Object({
        month: Type.Number(),
        day: Type.Number(),
        year: Type.Number(),
      }),
    ),
  }),
};

/** type validator with the complete set of fact values */
const AllFactValues = Type.Object(FactValues);

/** type validator that can contain any of the fact values */
export const FactValueValidator = Type.Partial(AllFactValues);

/** a type that can contain any fact value */
export type FactValuePartial = Static<typeof FactValueValidator>;

/** a type that contains all fact values */
export type FactValueSet = Static<typeof AllFactValues>;

// This will throw an error if you create a company or person fact and forget to add it to the "FactValues" constant
type FactFormatValidator<T extends FactType> = (val: FactValueSet[T]) => boolean;
type FactFormatValidatorMap = {
  [Type in keyof FactValueSet]: FactFormatValidator<Type>;
};

/** valid values for each fact type */
export const FactFormatValidator: Partial<FactFormatValidatorMap> = {
  [PersonFact.Birthday]: (val: string) => /^\d{2}-\d{2}$/.test(val),
  [PersonFact.Birthyear]: (val: number) => val > 1900 && val < 2100,
};

/** FactSet is a collection of available facts for a given entity */
export type FactSet = Partial<{
  [Type in keyof FactValueSet]: TypedFact<Type>;
}>;

export type FactPair<T extends FactType> = {
  type: T;
  value: FactValueSet[T];
};

/** a fact with a strongly-typed type and value */
export type TypedFact<T extends FactType> = Omit<Fact, "type" | "value"> & {
  type: T;
  value: FactValueSet[T];
};

// --- ensure no overlap in keys

type OverlappingKeys = Extract<
  keyof typeof EntityFact,
  keyof typeof PersonFact | keyof typeof CompanyFact
>;
type NoOverlap = [OverlappingKeys] extends [never] ? true : false;
const _ensureNoOverlap: NoOverlap = true;

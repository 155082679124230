import { getUnderlyingError } from "@/lib/miscUtils";
import { isAxiosError } from "axios";

Object.defineProperty(Error.prototype, "toJSON", {
  value: function () {
    const self = this as Error;
    // This allows for errors to be formatted nicely in JSON from the logger
    // or JSON.stringify(error).
    return {
      name: self.constructor?.name || self.name,
      message: self.message?.substring(0, 500),
      cause: self.cause,
      stack: self.stack
        ?.replace(
          // remove redundant first line in stacktrace
          new RegExp(
            String.raw`^${self.name}: ${self.message?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}\n\s*at\s*`,
          ),
          "",
        )
        .split(/\n\s*/)
        .map((line: string) => line.replace(/^at /, "")),
    };
  },
  configurable: true,
  writable: true,
});

// Claude says we should explicitly set up the base error class
// Uses Object.setPrototypeOf to ensure proper inheritance from Error,
// fixing the prototype chain for correct instanceof behavior.
export class CrawlError extends Error {
  name = "CrawlError";
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class LinkedinCrawlError extends CrawlError {
  name = "LinkedinCrawlError";
}
export class CrunchbaseCrawlError extends CrawlError {
  name = "CrunchbaseCrawlError";
}
export class RetriesExhaustedCrawlError extends CrawlError {
  name = "RetriesExhaustedCrawlError";
}
export class FallbackCrawlError extends CrawlError {
  name = "FallbackCrawlError";
}
export class LambdaCrawlError extends CrawlError {
  name = "LambdaCrawlError";
}
export class LambdaRetriesExhaustedCrawlError extends LambdaCrawlError {
  name = "LambdaRetriesExhaustedCrawlError";
}

export class UserVisibleError extends Error {
  name = "UserVisibleError";
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, UserVisibleError.prototype);
  }
}

export function isTimeoutError(error: unknown): boolean {
  const underlyingError = getUnderlyingError(error);
  const errorString =
    isAxiosError(underlyingError) ?
      (underlyingError.code ?? underlyingError.message)
    : String(underlyingError);

  return ["ECONNABORTED", "ETIMEDOUT"].some((str) => errorString.includes(str));
}

type LinkedInApiUser = {
  email: string | null;
  id: string;
};

export type LinkedInApiErrorCode =
  | "BAD_COOKIE"
  | "NO_COOKIE"
  | "NOT_FOUND"
  | "RATE_LIMIT"
  | "SERVER_ERROR";

export class LinkedInApiError extends Error {
  name = "LinkedInApiError";
  code?: LinkedInApiErrorCode;
  user?: LinkedInApiUser;
  apiPath?: string;
  constructor(
    message?: string,
    options?: ErrorOptions & {
      user?: LinkedInApiUser;
      code?: LinkedInApiErrorCode;
      apiPath?: string;
    },
  ) {
    super(message, options);
    Object.setPrototypeOf(this, LinkedInApiError.prototype);
    this.code = options?.code;
    this.user = options?.user;
    this.apiPath = options?.apiPath;
  }
}

export interface LinkedInAuthWarning {
  code: LinkedInApiErrorCode;
  user: LinkedInApiUser;
}

export function isLinkedInAuthWarning(
  error: unknown,
): error is LinkedInApiError & LinkedInAuthWarning {
  if (!(error instanceof LinkedInApiError)) return false;

  const { code, user } = error;
  return !!(user && user.id && code && ["BAD_COOKIE", "NO_COOKIE", "RATE_LIMIT"].includes(code));
}

import { useStore } from "@nanostores/react";
import { uiStore } from "@/stores/uiStore";
import { useCallback } from "react";
import Modal from "@/components/modals/Modal";
import Button, { ButtonLink, ButtonVariant } from "@/components/ui/Button";
import VisitProgressBar from "@/components/visitLimit/VisitProgressBar";

export default function VisitLimitReachedModal() {
  const show = useStore(uiStore.showVisitLimitReachedModal);
  const user = useStore(uiStore.user);
  const maxEntityVisits = useStore(uiStore.maxEntityVisits);
  const entityVisitCount = useStore(uiStore.entityVisitCount);
  const profileVisits = (entityVisitCount?.visitedIds as string[])?.length || 0;
  const setOpen = useCallback((open: boolean) => {
    uiStore.showVisitLimitReachedModal.set(open);
  }, []);

  if (!show) return null;

  return (
    <Modal open={show} setOpen={setOpen}>
      <div className="bg-white rounded-lg max-w-[700px]">
        <div className="flex flex-col">
          <div className="p-4 w-full text-left font-semibold">
            You have reached your view limit.
          </div>
          <hr className="w-full" />
          <div className="flex flex-row p-4 gap-8">
            <div className="flex flex-col w-1/2">
              <div className="text-left font-medium mb-4">
                Increase your profile view limit by connecting LinkedIn to your Distill profile
              </div>
              <div className="mb-2 text-left font-medium text-sm">Profile views:</div>
              <div>
                <VisitProgressBar
                  profilesVisited={profileVisits}
                  maxProfiles={maxEntityVisits}
                  heightClass={"h-3"}
                />
              </div>
            </div>
            <div className="w-1/2 flex flex-col">
              <div className="grow"></div>
              <ButtonLink
                variant={ButtonVariant.Primary}
                href={"/setup"}
                onClick={() => setOpen(false)}
              >
                <div className="w-full font-medium">Connect your LinkedIn profile</div>
              </ButtonLink>
            </div>
          </div>
          <hr className="w-full my-2" />
          <div className="flex flex-row p-4 items-center gap-8">
            <div className="text-left text-sm w-1/2">
              Upgrade to Pro and view more profiles without connecting to LinkedIn
            </div>
            <div className="flex flex-col w-1/2">
              <Button variant={ButtonVariant.Secondary} disabled>
                <div className="w-full font-medium">Upgrade to Pro (coming soon)</div>
              </Button>
              <div
                className="my-2 text-gray-500 cursor-pointer"
                onClick={() => {
                  uiStore.showSupportModal(user);
                }}
              >
                Contact support
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

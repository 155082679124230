import { addDays, isAfter, startOfDay } from "date-fns";
import { atom } from "nanostores";
import { toast } from "react-toastify";

import API from "@/client/api";
import errorTracker from "@/lib/errorTracker";
import fakeMeetings from "@/lib/fakeMeetings";
import { loggerWithPrefix } from "@/lib/logger";
import { prettyError } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import { CalendarData, Meeting, oauthTokenMeta } from "@/types";

const logger = loggerWithPrefix("[meetingStore]");

class MeetingStore {
  initialized = atom<boolean>(false);

  hasCalendars = atom<boolean>(true);

  calendars = atom<CalendarData[]>([]);

  showCalendarId = atom<boolean>(false);

  meetings = atom<Meeting[]>([]);

  attendees = atom<string[]>([]);

  hiddenEmails = atom<string[]>([]);

  selectedMeeting = atom<Meeting | null>(null);

  debugShowCalendars = atom<string[]>([]);

  debugFakeMeetings = atom<boolean>(global.window?.debugFakeMeetings);

  startDate = atom<Date>(new Date());

  // --- actions

  init = async () => {
    if (this.initialized.get()) return;

    uiStore.user.get() && this.hiddenEmails.set(uiStore.user.get()?.meta?.hidden_emails || []);

    const debugShowCalendars =
      typeof localStorage !== "undefined" && localStorage.getItem("debugShowCalendars");
    if (uiStore.showDevTools() && debugShowCalendars) {
      const parsed = JSON.parse(debugShowCalendars) as string[];
      if (Array.isArray(parsed)) this.debugShowCalendars.set(parsed);
    }

    let needsSync = false;
    try {
      const data = await API.listCalendars();
      this.hasCalendars.set(data.length > 0);
      const calendars: CalendarData[] = [];
      data.forEach((c) => {
        const meta = oauthTokenMeta(c);
        if (meta.calendars) calendars.push(...meta.calendars);
        else calendars.push({ id: meta.email, name: meta.email });

        if (!c.syncedAt || isAfter(new Date(), addDays(new Date(c.syncedAt), 1))) needsSync = true;
      });
      this.calendars.set(calendars);

      await this.listMeetings();
    } catch (e) {
      errorTracker.sendError(e);
      toast.error(prettyError(e));
    } finally {
      this.initialized.set(true);
    }

    if (needsSync) await this.sync();
  };

  setDate = async (date: Date) => {
    this.startDate.set(date);
    if (!this.initialized.get()) {
      await this.init();
    } else {
      await this.listMeetings(date);
      // sync meetings if fetching in the past or future since we don't get them by default
      if (Math.abs(date.getTime() - new Date().getTime()) > 24 * 60 * 60 * 1000) {
        await this.sync();
      }
    }
  };

  showUserCalendar = () => {
    uiStore.showInputModal.set({
      type: "add",
      title: "Show calendar",
      subtitle: "Enter the email address of the calendar you want to show",
      fields: [
        {
          placeholder: "Email address",
        },
      ],
      onSubmit: async (values: string[]) => {
        const email = values[0];
        const setting = this.debugShowCalendars.get();
        const newSetting = [...setting, email];
        if (typeof localStorage !== "undefined")
          localStorage.setItem("debugShowCalendars", JSON.stringify(newSetting));
        this.debugShowCalendars.set(newSetting);
        await this.listMeetings();
      },
    });
  };

  hideUserCalendar = (email: string) => {
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Hide calendar",
      subtitle: `Stop showing ${email}'s calendar?`,
      onClick: () => {
        const setting = this.debugShowCalendars.get();
        const newSetting = setting.filter((e) => e !== email);

        if (typeof localStorage !== "undefined")
          localStorage.setItem("debugShowCalendars", JSON.stringify(newSetting));
        this.debugShowCalendars.set(newSetting);
        void this.listMeetings();
      },
    });
  };

  getShownCalendars = () => {
    const userCalendars = this.debugShowCalendars.get();
    return userCalendars;
  };

  listMeetings = async (date?: Date) => {
    if (this.debugFakeMeetings.get()) {
      this.setMeetings(fakeMeetings(date));
      return;
    }

    const dateString = startOfDay(date || new Date()).toISOString();

    try {
      const userCalendarsEmails = this.debugShowCalendars.get();
      const meetings = await API.meetings.list({
        user_calendars: userCalendarsEmails.length > 0 ? userCalendarsEmails.join(",") : undefined,
        date: dateString,
      });
      logger.info("meetings", meetings);

      const selected = this.selectedMeeting.get();
      if (selected && !meetings.find((m) => m.id === selected.id)) {
        meetings.push(selected);
      }
      this.setMeetings(meetings);
    } catch (e) {
      toast.error(prettyError(e));
    }
  };

  sync = async () => {
    if (this.debugFakeMeetings.get()) return;
    const date = this.startDate.get();
    try {
      await API.syncCalendars({ date: date?.toISOString().substring(0, 10) });
      await this.listMeetings(date);
    } catch (e) {
      toast.error(prettyError(e));
    }
  };

  setMeetings = (meetings: Meeting[]) => {
    meetings.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    this.meetings.set(meetings);
    const calendarIds = new Set<string>(meetings.map((m) => m.calendarId));
    this.showCalendarId.set(calendarIds.size > 1);
  };

  /**
   * @deprecated this will not function as intended and is not used in the codebase
   */
  createMeeting = async (meeting: Partial<Meeting>) => {
    try {
      const newMeeting = await API.meetings.create(meeting);
      this.meetings.set([newMeeting, ...this.meetings.get()]);
      return newMeeting;
    } catch (e) {
      toast.error(prettyError(e));
    }
  };

  toggleHideEmail = async ({ email }: { email: string }) => {
    const user = uiStore.user.get();
    if (!user) {
      return;
    }

    const currentHidden = this.hiddenEmails.get();
    let newHidden: string[];
    if (currentHidden.includes(email)) {
      newHidden = currentHidden.filter((e) => e !== email);
    } else {
      newHidden = [...currentHidden, email];
    }
    this.hiddenEmails.set(newHidden);
    await uiStore.updateUserMeta({ hidden_emails: newHidden });
  };

  toggleFakeMeetings = () => {
    window.debugFakeMeetings = !window.debugFakeMeetings;
    this.debugFakeMeetings.set(window.debugFakeMeetings);
    if (window.debugFakeMeetings) void this.listMeetings(this.startDate.get());
  };
}

export const meetingStore = new MeetingStore();

declare global {
  interface Window {
    debugFakeMeetings: boolean;
  }
}

import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import React from "react";
import { Tooltip } from "react-tooltip";
import { favicon as favFunc } from "@/lib/miscUtils";
import moment from "moment";

export const USER_FEEDBACK_TOOLTIP_TITLE = "User-Submitted Information";

export default function SourceTooltip() {
  return (
    <Tooltip
      id="source-tooltip"
      className="bg-white border !rounded-lg z-10"
      opacity={1}
      variant="light"
      place="top"
      clickable
      render={({ content, activeAnchor }) => {
        if (!content || !activeAnchor) return null;

        return content.includes(USER_FEEDBACK_TOOLTIP_TITLE) ?
            <SourceTooltipContent href={content} noLink />
          : <SourceTooltipContent href={content} />;
      }}
    />
  );
}

export function SourceTooltipContent({ href, noLink }: { href: string; noLink?: boolean }) {
  const entityStore = useEntityStore();
  const sources = useStore(entityStore.sources);
  const source = sources.find((s) => s.url === href);

  const favicon =
    source?.url ?
      favFunc({
        url: source?.url,
        favicon: undefined,
      })
    : undefined;

  let domain = "";
  try {
    domain = new URL(href).hostname;
  } catch {
    domain = "";
  }

  return (
    <div className="w-96 flex flex-col p-1">
      <div className="font-semibold text-md mb-1">{source?.title}</div>
      {source?.updatedAt && (
        <div className="mb-3">Retrieved: {moment(source?.updatedAt).format("LL")}</div>
      )}
      <div className="flex flex-row items-stretch">
        {favicon && (
          <div className="flex items-center">
            <img
              src={favicon}
              className="w-4 h-4 rounded border border-gray-100 mr-1"
              alt="source-favicon"
            />
          </div>
        )}
        {!noLink && (
          <div>
            <a href={href} target="_blank" className="text-blue-500" rel="noreferrer">
              {domain || (href.includes("linkedin.com") ? "Linkedin" : href)}
              <ArrowTopRightOnSquareIcon className="w-3 h-3 -mt-1 ml-1 inline" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

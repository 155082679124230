import { SocialServiceIcon } from "@/components/icons/SocialServiceIcon";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { SocialService } from "@/utils/socialUtils";
import { useStore } from "@nanostores/react";
import { capitalize } from "lodash";
import React from "react";

const SocialButtons = () => {
  const entityStore = useEntityStore();
  const socials = useStore(entityStore.socials);

  const socialsToDisplay = [
    SocialService.GitHub,
    SocialService.Facebook,
    SocialService.Twitter,
    SocialService.LinkedIn,
  ];

  // Filter for socials to display and remove duplicates of displayable socials
  const availableSocials = socialsToDisplay
    .map((service) => socials.find((social) => (social.service as SocialService) === service))
    .filter((x) => !!x);

  const hiddenSocialsCount = socials ? socials.length - availableSocials.length : 0;

  const Icons = [
    ...availableSocials.map((social) => (
      <ConditionalLink
        key={social.service}
        href={social.url}
        className="rounded-sm text-gray-600 hover:text-gray-800"
        target="_blank"
        rel="noopener noreferrer"
        data-tooltip-id="tooltip"
        data-tooltip-content={capitalize(social.service)}
      >
        <SocialServiceIcon service={social.service as SocialService} className="h-5 w-5" />
      </ConditionalLink>
    )),
    hiddenSocialsCount > 0 && (
      <a
        href={`#${ProfilePageSection.SocialMedia}`}
        key="hiddenSocialsCount"
        className="flex flex-row text-white bg-gray-600 rounded-sm h-5 w-5 items-center justify-center hover:bg-gray-800"
      >
        <p className="text-md whitespace-nowrap">+{hiddenSocialsCount}</p>
      </a>
    ),
  ];

  return <div className="flex flex-row gap-3">{socials.length > 0 && Icons}</div>;
};

export default SocialButtons;

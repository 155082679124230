import ChatMessage from "@/components/ui/ChatMessage";
import { useEntityStore } from "@/stores/entityStore";
import { useMessageStore } from "@/stores/messageStore";
import { uiStore } from "@/stores/uiStore";
import type { UIMessage } from "@/types";
import { useStore } from "@nanostores/react";
import { useEffect, useRef, useState } from "react";

const SCROLL_THRESHOLD = 600;

export default function EntityAIChat() {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const user = useStore(uiStore.user);
  const messageStore = useMessageStore();
  const messages = useStore(messageStore.messages);
  const error = useStore(messageStore.error);
  const partialMessage = useStore(messageStore.partialMessage);

  const [input, setInput] = useState("");
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const prevMessagesRef = useRef<string | undefined>(undefined);
  const initialLoadRef = useRef(true);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      // Scroll to bottom on initial load
      if (initialLoadRef.current) {
        container.scrollTop = container.scrollHeight;
        initialLoadRef.current = false;
        return;
      }

      // Load more messages on scroll
      if (container.scrollTop < SCROLL_THRESHOLD && messageStore.hasMoreMessages()) {
        void messageStore.loadEntityChatMessages();
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [entity.id, messageStore]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const latestMessage = messageStore.getLatestMessage();
    const isNewMessage =
      latestMessage && (!prevMessagesRef.current || latestMessage.id !== prevMessagesRef.current);
    if (latestMessage) {
      prevMessagesRef.current = latestMessage.id;
    }

    // Auto scroll on new messages
    if (isNewMessage) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, messageStore]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const trimmedInput = input.trim();
    if (!trimmedInput) return;
    setInput("");

    await messageStore.exchangeEntityChatMessage(trimmedInput);
  };

  return (
    <div className="flex flex-col h-[350px]">
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto space-y-4 p-4">
        {messages.toReversed().map((message: UIMessage) => (
          <ChatMessage key={message.id} message={message} user={user} />
        ))}

        {partialMessage && (
          <ChatMessage
            message={{
              id: "partial",
              role: "assistant",
              content: partialMessage,
              createdAt: new Date(),
            }}
            user={user}
          />
        )}

        {error && <div className="text-red-500 text-sm p-2">{error}</div>}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="flex border rounded-lg border-gray-300 focus-within:border-brand-600">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask about this profile"
            onFocus={(e) => e.target.select()}
            className="flex-1 px-4 py-3 bg-transparent focus:outline-none border-0 focus:ring-0 text-sm h-12 flex items-center disabled:opacity-50"
          />
          <button
            type="submit"
            disabled={messageStore.inProgress.get()}
            className="bg-brand-600 text-white px-4 py-2 rounded-lg hover:bg-brand-700 min-w-[100px] disabled:opacity-50 text-md m-1"
          >
            {messageStore.inProgress.get() ? "Loading..." : "Ask AI"}
          </button>
        </div>
      </form>
    </div>
  );
}

import { JsonArray } from "@prisma/client/runtime/library";
import { EntityVisitCount } from "@prisma/client";
import { CookieFragments, UserMeta } from "@/types";

export const MAX_VISIT_COUNT_NO_COOKIE = 5;
export const MAX_VISIT_COUNT_WITH_COOKIE = 50;

// eslint-disable-next-line @typescript-eslint/max-params
export function canLoadEntity(
  entityId: string,
  entityVisitCount?: EntityVisitCount | null,
  cookieFragments?: CookieFragments,
  userMeta?: UserMeta,
) {
  // In order to load entity the user needs to have valid cookie, be within
  // limit, or have seen the profile before.
  // This is a product decision, so up to be changed at any point.
  const visitedEntities = (entityVisitCount?.visitedIds as JsonArray) || [];
  return (
    userMeta?.entity_id === entityId ||
    Object.keys(cookieFragments || {}).length > 0 ||
    visitedEntities.indexOf(entityId) !== -1 ||
    ((entityVisitCount?.visitedIds as JsonArray)?.length || 0) <= MAX_VISIT_COUNT_NO_COOKIE
  );
}

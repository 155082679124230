import { FactPick } from "@/types";
import { CompanyFact, TypedFact } from "@/models/facts/facts.types";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { aggregateHeadcount } from "@/utils/facts";
import { formatNumber } from "@/lib/stringUtils";

export default function HeadcountChart({
  historicalHeadcount,
}: {
  historicalHeadcount: FactPick<"HistoricalHeadcount"> | undefined;
}) {
  if (!historicalHeadcount) return null;
  const aggregatedHeadcount = aggregateHeadcount(historicalHeadcount.value);
  if (aggregatedHeadcount.length < 3) return null;
  return (
    <div className="w-full py-4">
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={aggregatedHeadcount} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
          <XAxis tickSize={10} dataKey="date" padding={{ left: 20, right: 20 }} tickLine={false} />
          <YAxis
            domain={[(dataMin: number) => dataMin * 0.7, "auto"]}
            tickFormatter={(tick) => {
              return formatNumber(tick as number);
            }}
          />
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <Tooltip
            formatter={(value, name, props) => {
              return [formatNumber(value as number), "Headcount"];
            }}
          />
          <Area
            type="monotone"
            dataKey="headcount"
            stroke="#2b2ba8"
            fill={"#a1b6ff"}
            dot={{ strokeWidth: 1, r: 4, fill: "#2b2ba8", fillOpacity: 1 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

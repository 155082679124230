import Avatar from "@/components/ui/Avatar";
import { UserWithMeta } from "@/types/db";
import { UserIcon } from "@heroicons/react/24/outline";
import { User } from "@prisma/client";
import Image from "next/image";

export default function MessageAvatar({
  role,
  user,
}: {
  role: string;
  user: User | UserWithMeta | null;
}) {
  return (
    <div className="w-8 h-8 flex-shrink-0 overflow-hidden rounded-lg flex items-center justify-center">
      {role === "assistant" ?
        <Image
          src="/icon-avatar.png"
          alt="Assistant"
          width={32}
          height={32}
          className="h-8 w-8 object-contain"
        />
      : <div className="w-full h-full flex items-center justify-center">
          {user ?
            <Avatar user={user} />
          : <UserIcon className="w-8 h-8 text-gray-500" />}
        </div>
      }
    </div>
  );
}

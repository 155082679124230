import ExperienceSection from "@/components/experiences/ExperienceSection";
import { ExperienceLabels } from "@/components/experiences/utils";
import Loader from "@/components/ui/Loader";
import {
  CompanyCompanyRelationship,
  RelationshipSource,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { useEntityStore } from "@/stores/entityStore";
import { profilePageUiStore } from "@/stores/profilePageUiStore";
import { findAttribute } from "@/stores/utils";
import { AttributeType, PipelineRunStatus, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";

interface Props {
  status: PipelineRunStatus;
}

function groupExperiences(experiences: RelationshipWithEntity[]) {
  const result: RelationshipWithEntity[][] = [];
  let previousToId = undefined;
  let previousToName = undefined;
  let currentGroupedExperiences = [];
  for (const experience of experiences) {
    // We are creating a new group if
    if (
      // We know the current group ID and it's different than current iteration
      (previousToId && experience.toId !== previousToId) ||
      // We do not know the current group ID, but we know the name and it differs
      (!previousToId && previousToName && experience.toName !== previousToName) ||
      // We know nothing - safer to not group
      (!previousToId && !previousToName && !experience.toId && !experience.toName)
    ) {
      result.push(currentGroupedExperiences);
      currentGroupedExperiences = [];
    }
    currentGroupedExperiences.push(experience);
    previousToId = experience.toId;
    previousToName = experience.toName;
  }
  if (currentGroupedExperiences.length > 0) result.push(currentGroupedExperiences);
  return result;
}

export default function PastExperiences() {
  const entityStore = useEntityStore();
  const relationshipSections = useStore(entityStore.relationshipSections);
  const addingProfileSections = useStore(profilePageUiStore.addingProfileSections);
  const relationshipsLoading = useStore(entityStore.relationshipsLoading);

  const workHistory = relationshipSections[ProfilePageSection.WorkHistory];
  const hasLegacyData =
    workHistory?.length == 0 &&
    (findAttribute(entityStore.attributes.get(), AttributeType.LinkedinProfile)?.value.experience
      .length || 0) > 0;

  if (relationshipsLoading)
    return (
      <div className="text-center mb-4">
        <Loader />
      </div>
    );

  if (hasLegacyData) {
    return <div className="text-center">No recent relationship data yet</div>;
  }
  return (
    <>
      {Object.keys(ExperienceLabels).map((section: string) => {
        const experiences = relationshipSections[section as ProfilePageSection] || [];
        if (
          !experiences.length &&
          section !== ProfilePageSection.WorkHistory &&
          !addingProfileSections[section as ProfilePageSection]
        )
          return null;

        if (section === ProfilePageSection.Investments) {
          return <InvestmentList key={section} />;
        }

        const allGroupedExperiences = groupExperiences(experiences);
        return (
          <ExperienceSection
            key={section}
            section={section as ProfilePageSection}
            allGroupedExperiences={allGroupedExperiences}
          />
        );
      })}
    </>
  );
}

export function InvestmentList() {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const relationships = useStore(entityStore.relationships);
  const investments = relationships.filter((r) => r.type === CompanyCompanyRelationship.InvestedIn);
  if (!investments.length) return null;

  const result: RelationshipWithEntity[][] = [];
  const companyToInvestments: { [key: string]: RelationshipWithEntity[] } = {};
  const crunchbaseInvestments = new Set<string>(
    investments
      .filter((r) => r.source == RelationshipSource.Crunchbase)
      .map((r) => r.toName)
      .filter(Boolean) as string[],
  );

  // sort by date
  investments.sort((a, b) => {
    const dateA = a.startedDate;
    const dateB = b.startedDate;
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return dateB.localeCompare(dateA);
  });

  // group by company regardless of date
  for (const investment of investments) {
    if (
      investment.toName &&
      investment.source == RelationshipSource.LinkedIn &&
      crunchbaseInvestments.has(investment.toName)
    ) {
      // favor crunchbase entries over linkedin for the same company - skip mentioning the linkedin ones
      continue;
    }
    // there are some bad scrapes where the entity is duplicated
    if (investment.toId === entity?.id) continue;
    const company = investment.toId || investment.toName || investment.key;
    if (!companyToInvestments[company]) {
      companyToInvestments[company] = [];
      result.push(companyToInvestments[company]);
    }
    companyToInvestments[company].push(investment);
  }

  // this can happen because of self-referencing investment scraping bugs
  if (result.length === 0) return null;

  return (
    <ExperienceSection section={ProfilePageSection.Investments} allGroupedExperiences={result} />
  );
}
